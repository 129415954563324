import { Modal } from '@/components';
import { FC, useCallback, Fragment } from 'react';
import { getDateWithFormat, secondsToTime } from '@/utils/dates';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';

interface LogModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaign: CampaignJSON;
}

const servicesSlugs: Array<keyof ApiCallsData> = ['moz', 'semrush', 'dataforseo', 'openai'];

const CampaignLogModalContent: FC<LogModalProps> = ({ isOpen, onClose, campaign }) => {
  const user = useSelector(selectAuthUser);
  const formatEntry = useCallback((entry: string) => {
    const parts = entry.split(' | ');

    if (parts.length < 2) {
      return entry;
    }

    return `${getDateWithFormat(parts[0], 'dd MMM  HH:mm:ss')} => ${parts.slice(1).join(' | ')}`;
  }, []);

  const lastRun = campaign.last_run;

  const getStatsText = useCallback(
    (run: Run) => (service: keyof ApiCallsData, index: number) => {
      const apiCallsData: ApiCallsData | null = run ? run.stats.api_calls : null;

      if (!service || !apiCallsData) {
        return '';
      }

      const { count, time, cost, tokens } = apiCallsData[service];

      return (
        <p key={index} className='text-sm'>
          <b className='capitalize'>{service}</b>: {count} [{time ? secondsToTime(time) : '?'}] {cost ? `$${cost}` : ''} {tokens ? `${tokens} Tokens` : ''}
        </p>
      );
    },
    [secondsToTime],
  );

  return (
    <Modal isOpen={isOpen} title='Logs' onClose={onClose} className='h-[calc(80vh)] w-modal-2xl pt-2'>
      <div className='h-full overflow-y-auto'>
        {campaign.log.length === 0 && <div className='mt-4'>No Campaign logs.</div>}
        {campaign.log.length > 0 && <p className='mb-2 text-sm font-bold'>Campaign #{campaign.id}</p>}
        {campaign.log.map((entry: string, index: number) => {
          return (
            <p key={index} className='my-1'>
              {formatEntry(entry)}
            </p>
          );
        })}
        {user?.isSuperuser && campaign.runs.length > 0 && (
          <Fragment>
            {campaign.runs.map((run: Run) => {
              return (
                <div className='mt-4' key={run.id}>
                  <p className='mb-1 text-sm font-bold'>Run #{run.id}</p>
                  <div className='mb-4 mt-1'>{servicesSlugs.map(getStatsText(run)).filter(Boolean)}</div>

                  {run.log.map((entry: string, index: number) => {
                    return (
                      <p key={index} className='my-1'>
                        {formatEntry(entry)}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default CampaignLogModalContent;
