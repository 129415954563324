import { Button, ErrorMessage, Modal, CompetitorsModal } from '@/components';
import { BASE_API_URL } from '@/constants/application';
import { useToggle } from '@/hooks/toggle';

import { selectAuthToken, selectAuthUser } from '@/store/auth/auth.selector';

import { useLazyGetCurrentUserQuery } from '@/store/users/users.api';
import { useRefineCampaignCompetitorsDomainsMutation, useLazyGetCampaignAnalysisQuery, useLazyGetCampaignQuery } from '@/store/campaign/campaign.api';
import { getGoogleSheetsUrl } from '@/utils/googleSheets';
import { getUserHasCredits } from '@/utils/users';
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { FC, Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import cn from '@/utils/style';
import { round } from '@/utils/numbers';
import CampaignFailsModal from '../CampaignFailsModal';

const RefineCampaign: FC = () => {
  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);
  const token = useSelector(selectAuthToken);
  const campaign = useSelector(selectCampaign);

  const [fetchCampaign] = useLazyGetCampaignQuery();
  const [fetchCampaignAnalysis] = useLazyGetCampaignAnalysisQuery();
  const [refine] = useRefineCampaignCompetitorsDomainsMutation();
  const [fetchUser] = useLazyGetCurrentUserQuery();

  const { value: isCompetitorModalOpen, toggle: toggleCompetitorModal } = useToggle();
  const { value: isFailsModalOpen, toggle: toggleFailsModal } = useToggle();

  const [hasCredits, setHasCredits] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState('');

  const isAdmin = useMemo(() => user?.company?.internal || user?.isSuperuser, [user]);
  const hasFails = useMemo(() => {
    if (!campaign?.last_run) {
      return false;
    }
    return campaign.last_run.failed_keywords.length > 0 || campaign.last_run.failed_urls.length > 0;
  }, [campaign]);
  const isCampaignKickedOff = useMemo(() => campaign?.status === 'kicked_off', [campaign?.status]);
  const maxUrlsReached = useMemo(() => {
    const maxUrl = user?.company?.urls_max || null;
    if (!maxUrl) return false;

    return Boolean(campaign && campaign.url_count >= maxUrl);
  }, [campaign, user]);

  const isLoadingAddURL = useMemo(() => {
    if (!campaign) {
      return false;
    }

    if (campaign.last_run?.status === 'queued') {
      return true;
    }

    if (campaign.last_run?.status === 'error') {
      return false;
    }

    return !['done', 'created', 'kicked_off'].includes(campaign.status);
  }, [campaign]);

  const refreshCampaign = useCallback(async () => {
    if (!campaign) {
      return true;
    }

    const anchorsQueued = campaign.anchors_generation_status && !['error', 'done'].includes(campaign.anchors_generation_status);
    const fetchQueued = campaign.last_run?.status === 'queued';

    if (['done', 'created', 'kicked_off'].includes(campaign.status) && !anchorsQueued && !fetchQueued) {
      return true;
    }

    const { status } = await fetchCampaign({ campaignId: String(campaign.id) }).unwrap();

    if (status === 'done' && !anchorsQueued && !fetchQueued) {
      await fetchCampaignAnalysis({
        campaignId: String(campaign.id),
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
        show_hidden: true,
      }).unwrap();
    }
  }, [campaign, fetchCampaign, fetchCampaignAnalysis]);

  useEffect(() => {
    const interval = setInterval(async () => await refreshCampaign(), 5000);

    return () => clearInterval(interval);
  }, [refreshCampaign]);

  useEffect(() => {
    refreshCampaign();
  }, []);

  const handleOpenAddUrlModal = useCallback(async () => {
    const user = await fetchUser().unwrap();

    if (getUserHasCredits(user)) {
      return navigate('add-url');
    }

    setHasCredits(false);
  }, [fetchUser, navigate]);

  const handleSpreadsheetDownload = useCallback(async () => {
    const authHeader = `Bearer ${token}`;

    try {
      setDownloadError('');
      setIsDownloading(true);
      const response = await fetch(`${BASE_API_URL}campaigns/${campaign?.id}/spreadsheet/download/`, {
        headers: {
          Authorization: authHeader,
        },
      });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      setIsDownloading(false);

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = `${campaign?.name}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      setDownloadError(error as string);
    }
  }, [campaign?.id, campaign?.name, token]);

  const handleCompetitorsModalConfirm = async ({ id, domains }: { id: string; domains: Array<CampaignCompetitorDomain> }) => {
    toggleCompetitorModal(false);

    await refine({ id, domains }).unwrap();

    if (campaign) {
      fetchCampaignAnalysis({
        campaignId: String(campaign.id),
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
      });
    }
  };

  if (!campaign) {
    return null;
  }

  return (
    <Fragment>
      <div className='flex items-center justify-between pb-4 xl:pb-6'>
        <div className='flex flex-row flex-nowrap items-baseline gap-2'>
          <h2 className='mr-2 font-semibold'>Analysis</h2>
          {campaign.last_run?.spreadsheet_id && isAdmin && (
            <a
              href={getGoogleSheetsUrl(campaign.last_run?.spreadsheet_id)}
              className='flex flex-row flex-nowrap gap-2 text-xs text-blue-400 hover:text-blue-500'
              target='_blank'
              rel='noreferrer'
            >
              <p>Sheet</p>
              <ArrowTopRightOnSquareIcon className='mx-auto h-4 w-4 font-bold' />|
            </a>
          )}
          {campaign.last_run?.spreadsheet_id && (
            <Fragment>
              <a
                onClick={isDownloading ? undefined : handleSpreadsheetDownload}
                className={cn('flex  gap-2 text-xs', isDownloading ? ' cursor-not-allowed text-gray-400 hover:text-gray-500' : 'cursor-pointer text-blue-400 hover:text-blue-500')}
              >
                <p>Download</p>
                {isDownloading ? <ArrowPathIcon className='mx-auto h-4 w-4 animate-spin font-bold' /> : <ArrowDownTrayIcon className='mx-auto h-4 w-4 font-bold' />}
              </a>
            </Fragment>
          )}
          {isAdmin && (
            <div className='m-2 ml-auto flex cursor-pointer flex-row items-center gap-2 text-xs text-blue-400' onClick={() => navigate('logs')}>
              |<p>Logs</p>
              <ArrowTopRightOnSquareIcon className='h-4 w-4 font-bold' />
            </div>
          )}
          {hasFails && (
            <div className='m-2 ml-auto flex cursor-pointer flex-row items-center gap-2 text-xs text-red-400' onClick={() => toggleFailsModal(true)}>
              |<p>Fails</p>
              <ArrowTopRightOnSquareIcon className='h-4 w-4 font-bold' />
            </div>
          )}
        </div>
        <div className='flex gap-5'>
          <Button onClick={() => navigate('refine-keywords')} disabled={isCampaignKickedOff} variant='outline-light' className='whitespace-nowrap'>
            Refine Keywords
          </Button>
          <Button onClick={() => toggleCompetitorModal(true)} disabled={isCampaignKickedOff} variant='outline-light' className='whitespace-nowrap'>
            Refine Competitors
          </Button>
          <Button
            isLoading={isLoadingAddURL}
            loadingText={`${round((campaign.last_run?.fetch_progress || 0) * 100, 0)} %`}
            onClick={handleOpenAddUrlModal}
            className='whitespace-nowrap'
            disabled={isCampaignKickedOff || maxUrlsReached}
          >
            Add URL
          </Button>
        </div>
      </div>
      {!hasCredits && <ErrorMessage className='justify-end'>You have no credits left. Please contact your account manager to purchase more credits.</ErrorMessage>}
      {maxUrlsReached && (
        <ErrorMessage className='justify-end'>
          You exceed the number of URLs allowed: {campaign.url_count} / {user?.company?.urls_max || 0}
        </ErrorMessage>
      )}

      <CompetitorsModal isOpen={isCompetitorModalOpen} onClose={() => toggleCompetitorModal(false)} onConfirm={handleCompetitorsModalConfirm} />

      {isFailsModalOpen && <CampaignFailsModal isOpen={isFailsModalOpen} onClose={() => toggleFailsModal(false)} />}

      {downloadError && (
        <Modal isOpen={true} onClose={() => setDownloadError('')}>
          <ErrorMessage>{downloadError}</ErrorMessage>
        </Modal>
      )}
    </Fragment>
  );
};

export default RefineCampaign;
