import { BASE_API_URL } from '@/constants/application';
import { getRandomNumber } from '@/utils/numbers';
import cn from '@/utils/style';
import { CHART_COLORS } from '@/utils/table';
import { FC, useMemo } from 'react';

interface AvatarProps {
  user: Omit<User, 'campaigns'>;
  displayName?: boolean;
  className?: string;
  containerClassName?: string;
}

const BASE_URL = `${BASE_API_URL?.replace('/v1/', '')}`;

const Avatar: FC<AvatarProps> = ({ user, displayName = true, className, containerClassName }) => {
  const profilePictureUrl = useMemo(() => user.profilePictureUrl, [user]);
  const avatarImage = user.firstName && user.lastName ? `${user.firstName.at(0)}${user.lastName.at(0)}` : user.email.at(0);
  const bgColor = useMemo(() => CHART_COLORS[getRandomNumber(1, CHART_COLORS.length - 1)], []);
  const displayedName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

  return (
    <div className={cn('flex flex-wrap items-center justify-center gap-x-2 break-all', containerClassName)}>
      {profilePictureUrl && (
        <div className={cn('flex h-8 w-8 items-center justify-center overflow-hidden rounded-full text-xl font-bold text-white', className)}>
          <img src={`${BASE_URL}${profilePictureUrl}`} alt='avatar' className='min-h-full rounded-full' />
        </div>
      )}
      {avatarImage && !profilePictureUrl && (
        <div className={cn('flex h-8 w-8 items-center justify-center rounded-full text-xl font-bold text-white', className)} style={{ backgroundColor: bgColor }}>
          {avatarImage?.toUpperCase()}
        </div>
      )}
      {displayName && <p className='m-2 text-center'>{displayedName}</p>}
    </div>
  );
};

export default Avatar;
