import Modal, { ModalProps } from '@/components/Modal';
import { FC } from 'react';

interface AlreadyExistingCombinationsModalProps extends ModalProps {
  alreadyExistingCombinations: Combination[];
}

const AlreadyExistingCombinationsModal: FC<AlreadyExistingCombinationsModalProps> = ({ isOpen, onClose, alreadyExistingCombinations }) => {
  if (!alreadyExistingCombinations.length) {
    return null;
  }

  return (
    <Modal title='Already existing combinations' className='max-w-modal-2xl' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col gap-2'>
        <p className='font-semibold'>One or more URLs / keywords combinations already exist in this campaign:</p>
        <div className='max-h-96 overflow-y-auto'>
          <ol className='list-decimal pl-6'>
            {alreadyExistingCombinations.map((combination) => {
              if (combination.keywords.length === 0) {
                return null;
              }

              return (
                <li key={combination.url} className='pb-2'>
                  <p className='font-semibold'>{combination.url}</p>
                  <ul className='list-disc pl-6'>
                    {combination.keywords.map((keyword) => (
                      <li key={keyword}>{keyword}</li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </Modal>
  );
};

export default AlreadyExistingCombinationsModal;
