import { useCallback, useMemo, useState } from 'react';

const useTrackTableChanges = () => {
  const [changes, setTrackChanges] = useState<Array<number | string>>([]);

  const trackTableChanges = useCallback((id: number | string) => {
    setTrackChanges((prev) => {
      const tracked = prev.includes(id);

      if (tracked) {
        return prev.filter((tableId) => tableId !== id);
      }

      return [...prev, id];
    });
  }, []);

  const resetChanges = useCallback(() => setTrackChanges([]), []);

  const hasChanges = useMemo(() => changes.length > 0, [changes]);

  return { changes, trackTableChanges, resetChanges, hasChanges };
};

export default useTrackTableChanges;
