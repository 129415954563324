import { SearchBarDebounce, VirtualizedTable } from '@/components';
import { TopKeywordSubComponent } from '@/components/OrganicResearchModal/OrganicResearchModal';
import OrganicResearchTableFooter from '@/components/OrganicResearchModal/OrganicResearchTableFooter';
import useOrganicResearchColumns from '@/components/OrganicResearchModal/useOrganicResearchColumns';
import { useOrganicResearchTable, useSelectUrls } from '@/hooks/organicResearch';
import { useGetCampaignQuery, useGetDomainOrganicPagesQuery } from '@/store/campaign/campaign.api';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Row } from '@tanstack/react-table';
import { useField } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PAGE_SIZE = 30;

const OrganicResearchContent = () => {
  const [field] = useField<string>('domain');
  const [, , { setValue }] = useField<string>('urls_list');
  const [combinations] = useField<Combination[]>('combinations');
  const navigate = useNavigate();
  const [params, setParams] = useState({ search: '', offset: 0, limit: PAGE_SIZE });
  const { campaignId } = useParams() as { campaignId: string };
  const [selectedUrls, toggleSelectedUrl, toggleAll] = useSelectUrls();
  const { data: campaignData, isLoading: isCampaignLoading } = useGetCampaignQuery({
    campaignId,
    include: ['country'],
  });

  const {
    data: organicPages,
    isLoading: isOrganicPagesLoading,
    isFetching: isOrganicPagesFetching,
  } = useGetDomainOrganicPagesQuery({ url: field.value, country: campaignData?.country || 'us', ...params }, { skip: !campaignData || !campaignData.country });
  const data = useMemo(() => organicPages?.results || [], [organicPages]);

  const loadingState = isOrganicPagesLoading || isOrganicPagesFetching || isCampaignLoading;

  const organicResearchColumns = useOrganicResearchColumns({ selectedUrls, toggleSelectedUrl, toggleAll });

  const [table, flatRows, width] = useOrganicResearchTable(data, organicResearchColumns);

  const selectInitialUrls = useCallback(() => {
    flatRows.forEach((row) => {
      const list = combinations.value.map((c) => c.url);
      if (list.includes(row.original.url)) {
        toggleSelectedUrl(row.original.url);
      }
    });
  }, [flatRows, combinations.value, toggleSelectedUrl]);

  const handleConfirm = useCallback(() => {
    setValue(selectedUrls.join(','));
    return navigate('../manual');
  }, [setValue, selectedUrls, navigate]);

  const handleSearch = useCallback(
    (value: string) => {
      if (value === params.search) return;

      table.toggleAllRowsExpanded(false);
      setParams((old) => ({ ...old, search: value, offset: 0, limit: PAGE_SIZE }));
    },
    [table, params],
  );

  const handleFetchAdditional = useCallback(() => {
    setParams((old) => ({ ...old, offset: old.offset + PAGE_SIZE, limit: old.limit + PAGE_SIZE }));
  }, []);

  const renderSelectedRows = (url: string) => (
    <div key={url} className='flex w-fit flex-nowrap items-center gap-2 whitespace-nowrap rounded-lg bg-blue-100 px-3 py-2'>
      <p>{url}</p>
      <button onClick={() => toggleSelectedUrl(url)}>
        <XMarkIcon className='w-5' />
      </button>
    </div>
  );

  const renderSub = (row: Row<OrganicResearch>) => <TopKeywordSubComponent row={row} country={'us'} />;

  return (
    <div className='flex flex-col gap-5' ref={selectInitialUrls}>
      <SearchBarDebounce delay={750} onChange={handleSearch} inputClassName='w-full h-10' className='w-full' isFetching={isOrganicPagesFetching} />
      <VirtualizedTable
        name='organicResearch'
        table={table}
        allowResize
        subRowContent={renderSub}
        itemHeight={100}
        className='overflow-x-auto'
        containerClass='max-h-[calc(60vh-12rem)]'
        style={{ maxWidth: width * 0.77 }}
        isLoading={loadingState}
        loadingText={'Loading Pages...'}
      />
      {selectedUrls.length > 0 && (
        <div className='flex max-h-24 flex-wrap items-center gap-3 overflow-y-auto rounded-lg bg-slate-100 p-4' style={{ maxWidth: width * 0.77 }}>
          {selectedUrls.map(renderSelectedRows)}
        </div>
      )}
      <OrganicResearchTableFooter onConfirm={handleConfirm} onFetchAdditional={handleFetchAdditional} isLoading={loadingState} amount={selectedUrls.length} />
    </div>
  );
};

export default OrganicResearchContent;
