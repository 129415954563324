import { getLinksToBuild, transformCampaignUrlToScenarioUrl } from '@/utils/urls';
import { Row, Table } from '@tanstack/react-table';
import { FC, Fragment, memo, useCallback, useMemo } from 'react';
import CloseToGapField from '../CloseToGapField';
import SelectUrlField from '../SelectUrlField';
import { useSelector } from 'react-redux';
import { selectEditAllScenarios } from '@/store/campaign/campaign.selector';
import { useNavigate } from 'react-router-dom';
import { ClipboardDocumentListIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Tooltip, ToastCard } from '@/components';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

interface CloseToGapCellProps {
  table: Table<AnalysisDataWithFailedUrls>;
  row: Row<AnalysisDataWithFailedUrls>;
  scenario: Scenario;
  hasAnchorsText?: boolean;
}

type TableMetadata = {
  toggleUrl: (url: ScenarioUrl, isEditAllScenarios: boolean) => void;
  setUrlCloseToGap: ({ url_id, close_to_gap }: { url_id: number; close_to_gap: number }) => void;
  onBeforeNavigate?: () => void;
};

const CloseToGapCell: FC<CloseToGapCellProps> = ({ scenario, table, row, hasAnchorsText = false }) => {
  const tableMetadata = table.options.meta as TableMetadata;
  const editAllScenarios = useSelector(selectEditAllScenarios);
  const navigate = useNavigate();
  const url = useMemo(() => {
    const ltb = Number(
      getLinksToBuild({
        closeToGap: Number(row.original.closeToGap),
        lrdGap: row.original.lrdGap,
        projLength: scenario.proj_length,
      }),
    );

    return transformCampaignUrlToScenarioUrl({ ...row.original, linksToBuild: row.original.lrdGap ? ltb : 0 });
  }, [row.original, scenario]);

  const updateScenarioUrls = useCallback(() => {
    tableMetadata.toggleUrl(url, editAllScenarios);
  }, [tableMetadata, editAllScenarios, url]);

  const handleNavigate = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      tableMetadata.onBeforeNavigate && tableMetadata.onBeforeNavigate();
      navigate(`${row.original.targetPageId}`);
    },
    [navigate, row.original.targetPageId, tableMetadata],
  );

  const handleOnCopy = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const result = copy(row.original.targetPage);

      if (result) {
        return toast(<ToastCard message='URL copied!' />, { type: 'success', autoClose: 1000 });
      }
    },
    [row.original.targetPage],
  );
  return (
    <Fragment>
      <div className='absolute top-2 flex flex-nowrap items-center gap-2 whitespace-nowrap'>
        <SelectUrlField
          disabled={row.original.isFailed || row.original.isEmptyData}
          scenario={scenario}
          urlId={row.original.targetPageId}
          hasAnchorsText={hasAnchorsText}
          updateScenarioUrls={updateScenarioUrls}
        />
        {row.original.isEmptyData ? (
          <div className='z-[1] flex gap-2'>
            <div className='flex justify-center gap-2'>
              {row.original.targetPage}
              <button className='pr-2' onClick={handleOnCopy}>
                <ClipboardDocumentListIcon className='w-5 text-slate-500' />
              </button>
            </div>
            {row.original.isFailed && (
              <Tooltip content={<div>{row.original.message}</div>} id={row.original.url} clickable={false}>
                <ExclamationTriangleIcon className=' size-5 text-red-500' />
              </Tooltip>
            )}
          </div>
        ) : (
          <button onClick={handleNavigate} className='z-[1] flex justify-center gap-2'>
            {row.original.targetPage}
            <div className='pr-2' onClick={handleOnCopy}>
              <ClipboardDocumentListIcon className='w-5 text-slate-500' />
            </div>
          </button>
        )}
      </div>
      <CloseToGapField
        url={url}
        table={table as Table<unknown>}
        urlId={row.original.targetPageId}
        lrdGap={row.original.lrdGap}
        rowId={row.id}
        scenario={scenario}
        hasAnchorsText={hasAnchorsText}
      />
    </Fragment>
  );
};

export default memo(CloseToGapCell);
