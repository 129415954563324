import { VirtualizedTable, TableVisibilityMenu } from '@/components';
import { useTable } from '@/hooks/table';
import { useGetKeywordAnalysisQuery, useLazyGetCampaignAnalysisQuery, useRefineCampaignKeywordsMutation } from '@/store/campaign/campaign.api';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useKeywordAnalysisColumns from './KeywordAnalysisColumns';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { selectTablesConfig } from '@/store/app/app.selector';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import cn from '@/utils/style';
import { useToggle } from '@/hooks/toggle';
import AllKwsExcludedModal from '../AllExcludedKwsModal';

const KEYWORDS_ANALYSIS_TABLE_HEADERS_MAP = {
  closeToGap: 'Close to Gap',
  keyword: 'Keyword',
  totalVolume: 'Search Volume',
  difficulty: 'Difficulty (SEMR)',
  campaignRank: 'Avg Rank',
  pageAuthorityDiff: 'Page Authority',
  linksRootDomainDiff: 'Links / Domain',
  lrdGap: 'Domain Gap',
  volumeGapRatio: 'Gap Index',
  backlinkDADistribution: 'Backlink DA Distro',
  ageAverage: 'Link Age (Avg)',
  velocityDiff_6months: 'Velocity Diff.',
  contextRelevanceScoreAvg: 'Link Relevance',
  anchorTextBuckets: 'Anchor Text Buckets',
  serpFeaturesByKeyword: 'SERP Features',
};

const KeywordsAnalysisTable: FC = () => {
  const { campaignId, targetPageId } = useParams() as { campaignId: string; targetPageId: string };

  const campaign = useSelector(selectCampaign);
  const tableConfig = useSelector(selectTablesConfig);

  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const { value: isAllKwsExcludedModalOpen, toggle: toggleAllKwsExcludedModal } = useToggle();
  const navigate = useNavigate();

  const columns = useKeywordAnalysisColumns();

  const { data: kwAnalysis, isLoading } = useGetKeywordAnalysisQuery({ campaignId, urlId: targetPageId });

  const [refineKeywords, { isLoading: isRefineKeywordsLoading }] = useRefineCampaignKeywordsMutation();
  const [fetchCampaignAnalysis, { isLoading: isAnalysisLoading, isFetching: isAnalysisFetching }] = useLazyGetCampaignAnalysisQuery();

  const tableLoading = useMemo(() => isRefineKeywordsLoading || isAnalysisLoading || isAnalysisFetching, [isRefineKeywordsLoading, isAnalysisLoading, isAnalysisFetching]);

  const onCheck = async (keywords: Keyword[]) => {
    await refineKeywords({ campaignId, keywords }).unwrap();

    if (campaign) {
      await fetchCampaignAnalysis({
        campaignId: String(campaign.id),
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
        show_hidden: true,
      }).unwrap();
    }
  };

  const [table, flatRows] = useTable({
    data: kwAnalysis?.results || [],
    columns,
    defaultSorting: tableConfig['keywordsAnalysis'].sorting || [{ id: 'totalVolume', desc: true }],
    tableCustomOptions: ['allowFilters', 'allowSelect', 'allowSort'],
    tableOptions: {
      onColumnFiltersChange: setColumnFiltersState,
      initialState: {
        columnPinning: {
          left: ['closeToGap', 'keyword'],
        },
      },
      state: {
        columnVisibility: tableConfig['keywordsAnalysis'].visibility,
        columnFilters: columnFiltersState,
      },
      meta: {
        onCheck,
        toggleModal: toggleAllKwsExcludedModal,
        maxGapRatio: kwAnalysis ? kwAnalysis.maxGapRatio : 100,
      },
    },
  });

  const handleExcludedModalConfirm = async () => {
    toggleAllKwsExcludedModal(false);
    const keywords = flatRows.map((row) => ({ ...row.original.keyword, excluded: true }));
    await onCheck(keywords);
    navigate('../analysis');
  };

  useEffect(() => {
    flatRows.forEach((row) => {
      row.toggleSelected(!row.original.keyword.excluded);
    });
  }, [flatRows]);

  return (
    <div className='rounded-md bg-white p-6'>
      {isAllKwsExcludedModalOpen && (
        <AllKwsExcludedModal isOpen={isAllKwsExcludedModalOpen} onClose={() => toggleAllKwsExcludedModal(false)} onConfirm={handleExcludedModalConfirm} />
      )}
      <div className='mb-2 flex w-full items-center justify-between'>
        <p className=' text-start'>Keywords</p>
        <TableVisibilityMenu table={table} name={'keywordsAnalysis'} labels={KEYWORDS_ANALYSIS_TABLE_HEADERS_MAP} />
      </div>
      <VirtualizedTable
        table={table}
        isLoading={isLoading}
        name='keywordsAnalysis'
        loadingText='Loading data...'
        cellStyle='border-b border-gray-200'
        rowStyle='last-child:border-b-0'
        className={cn('mb-5', tableLoading && 'pointer-events-none opacity-50')}
        tableClass='shadow-none'
        containerClass='2xl:max-h-[calc(70vh-12rem)] max-h-[calc(60vh-12rem)] -mb-5'
        headerClass={cn('text-sm', tableLoading && 'pointer-events-none opacity-50')}
        itemHeight={86.5}
      />
    </div>
  );
};

export default KeywordsAnalysisTable;
