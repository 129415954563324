import { ColumnFiltersField, Modal, Pill } from '@/components';
import { NUMERIC_FILTERS, NUMERIC_FILTERS_OPTIONS, STRING_FILTERS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { MAP_FIELD_TO_TITLE } from '@/utils/campaigns';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as yup from 'yup';

const EXCLUDED_FILTERS = ['page', 'page_size', 'sort'];

interface CampaignsFiltersProps {
  onSubmit: (values: ColumnFiltersFormValues) => void;
  initialValue: Partial<ExtraFilters>;
  showPills?: boolean;
}

const columnFilters: Partial<CampaignColumnFilters> = {
  name: STRING_FILTERS_OPTIONS,
  status: STRING_FILTERS_OPTIONS,
  keyword_count: NUMERIC_FILTERS_OPTIONS,
  url_count: NUMERIC_FILTERS_OPTIONS,
  country: STRING_FILTERS_OPTIONS,
};

const filtersOptionsScheme: yup.SchemaOf<ColumnFilterValue> = yup.object({
  field: yup.mixed().required('field is required'),
  filter: yup
    .mixed()
    .oneOf([...NUMERIC_FILTERS, ...STRING_FILTERS])
    .required('filter is required'),
  value: yup.string().required('value is required'),
});

const filtersValidationScheme: yup.SchemaOf<ColumnFiltersFormValues> = yup.object({
  filters: yup.array().of(filtersOptionsScheme),
});

const CampaignsFilters: FC<CampaignsFiltersProps> = ({ onSubmit, initialValue, showPills = true }) => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const initialFilters = useMemo(() => {
    return Object.keys(initialValue).reduce((acc: Array<ColumnFilterValue>, val) => {
      const [key, filter] = val.split('__');

      if (EXCLUDED_FILTERS.includes(key)) {
        return acc;
      }

      acc.push({
        field: key as AllowedFilters,
        filter: (filter as FiltersOptions) || 'exact',
        value: initialValue[val as keyof ExtraFilters] as string, // fix this type (error on sort: Array<string> and sort is not part of this filtering)
      });
      return acc;
    }, []);
  }, [initialValue]);

  const formik = useFormik<ColumnFiltersFormValues>({
    onSubmit: (values) => {
      setIsFiltersModalOpen(false);
      onSubmit(values);
    },
    initialValues: {
      filters: initialFilters,
    },
    validationSchema: filtersValidationScheme,
  });

  const openFiltersModal = () => setIsFiltersModalOpen(true);
  const closeFiltersModal = () => setIsFiltersModalOpen(false);

  const renderPills = (filter: ColumnFilterValue) => {
    const columnFilter = columnFilters[filter.field] || [];
    const column = columnFilter.find((i: OptionType<StringFilters> | OptionType<NumericFilters>) => i.value === filter.filter) || { label: 'unknown' };

    return (
      <Pill key={`${filter.field}|${filter.filter}`} className='mx-1' showClose={false}>
        <p className='m-auto text-sm'>
          {MAP_FIELD_TO_TITLE[filter.field]} {column.label.toLowerCase()} {filter.value}
        </p>
      </Pill>
    );
  };

  return (
    <div className='flex items-center'>
      <div className='flex cursor-pointer flex-row flex-nowrap items-center gap-2 text-sky-600' onClick={openFiltersModal}>
        <p className='text-lg font-bold'>Filters</p>
        <FunnelIcon className='h-6 w-6' />
      </div>
      {isFiltersModalOpen && (
        <Modal isOpen={isFiltersModalOpen} title='Filters' onClose={closeFiltersModal} onConfirm={formik.submitForm}>
          <div className='w-96'>
            <FormikProvider value={formik}>
              <Form>
                <ColumnFiltersField name='filters' options={columnFilters} />
              </Form>
            </FormikProvider>
          </div>
        </Modal>
      )}
      {showPills && formik.values.filters.map(renderPills)}
    </div>
  );
};

export default CampaignsFilters;
