import { getColumnPinningStyles } from '@/hooks/utils';
import cn from '@/utils/style';
import { PlayIcon } from '@heroicons/react/24/solid';
import { Column, ColumnSort, HeaderGroup, Header as HeaderType, flexRender } from '@tanstack/react-table';
import { useCallback } from 'react';

interface HeaderProps<T> {
  headerGroup: HeaderGroup<T>;
  className?: string;
  onSortChange?: (columnSort: ColumnSort) => void;
  allowResize?: boolean;
  onResize?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, header: HeaderType<T, unknown>) => void;
}

const Header = <T extends unknown>({ headerGroup, className, onSortChange, allowResize = false, onResize }: HeaderProps<T>) => {
  const getPinningStyles = useCallback((column: Column<T, unknown>, isHeader = false) => getColumnPinningStyles(column, isHeader), []);

  const renderHeaders = useCallback(
    (header: HeaderType<T, unknown>) => {
      const isSorted = header.column.getIsSorted();
      const canSort = header.column.getCanSort();

      const handleSorting = () => {
        if (!canSort) {
          return;
        }

        onSortChange &&
          onSortChange({
            id: header.id,
            desc: !(isSorted === 'desc'),
          });
      };

      const handleDefaultSorting = () => {
        if (!canSort) {
          return;
        }
        header.column.toggleSorting(!(isSorted === 'desc'));
      };

      return (
        <th
          key={header.id}
          className='group relative cursor-pointer bg-slate-100 p-2 text-center'
          style={{ width: allowResize ? `calc(var(--header-${header.id}-size) * 1px)` : header.getSize(), ...getPinningStyles(header.column, true) }}
        >
          <div onClick={onSortChange ? handleSorting : handleDefaultSorting} className={cn('flex h-full items-center gap-2 font-bold', className)}>
            <span className='w-full'>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</span>
            {canSort ? isSorted ? <PlayIcon className={cn('w-3 rotate-90', isSorted === 'asc' && '-rotate-90', 'flex-grow-0')} /> : <span className='w-3' /> : null}
          </div>
          {header.column.getCanResize() && allowResize && (
            <div
              onMouseDown={(e) => allowResize && onResize && onResize(e, header)}
              className={cn(
                'absolute right-0 top-0 h-full w-1 cursor-col-resize touch-none select-none bg-slate-100 opacity-50 hover:bg-slate-300',
                header.column.getIsResizing() && 'opacity-0',
                'duration-150 group-hover:bg-slate-300',
              )}
            />
          )}
        </th>
      );
    },
    [className, onSortChange, allowResize, onResize, getPinningStyles],
  );

  return (
    <tr className={cn('first-child-first:rounded-tl-md first-child-last:rounded-tr-md', allowResize && 'flex')} key={headerGroup.id}>
      {headerGroup.headers.map(renderHeaders)}
    </tr>
  );
};

export default Header;
