import { ChartTooltipContent, ColumnBarChart, ColumnPieChart, Menu, SERPFeaturesCell, Tooltip, TrendCell } from '@/components';
import { selectMaxGapRatio, selectSelectedScenario } from '@/store/campaign/campaign.selector';
import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { getLinksToBuild, transformCampaignUrlToScenarioUrl } from '@/utils/urls';
import { FilterFnOption, Row, Table, createColumnHelper } from '@tanstack/react-table';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AnchorTextTooltipContent, BacklinkDADistributionTooltipContent, VelocityTooltipContent } from '../AnchorTextTooltipContent';
import CloseToGapCell from '../CloseToGapCell';
import LinksToBuildField from '../LinksToBuildField';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { selectAuthUser } from '@/store/auth/auth.selector';
import LCRIcon from '@/components/Icons/LCRIcon';

interface OverviewActionsMenuProps {
  menuItems: Array<Item>;
}

type TableMetadata = {
  createLCR: (url: string) => void;
};

const columnHelper = createColumnHelper<AnalysisDataWithFailedUrls>();

const getUrlLTB = (row?: ScenarioUrl, term?: number) => {
  if (row?.close_to_gap && row.lrd_gap > 0) {
    return Number(getLinksToBuild({ closeToGap: row.close_to_gap, lrdGap: row.lrd_gap, projLength: term }));
  }

  return row?.links_to_build;
};

const getSortingValue = (a?: number, b?: number) => {
  if (a === undefined || b === undefined || a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

const stringFilter = 'stringFilter' as FilterFnOption<AnalysisData>;
const numberFilter = 'numberFilter' as FilterFnOption<AnalysisData>;

const OverviewActionsMenu: FC<OverviewActionsMenuProps> = ({ menuItems }) => {
  if (menuItems.length === 0) {
    return null;
  }

  return (
    <div className='absolute right-2 top-2'>
      <Menu
        items={menuItems}
        direction='left'
        menuIcon={<EllipsisVerticalIcon className='size-8' />}
        containerClassName='z-20 w-fit text-sm font-semibold font-inter top-0 right-full mt-0'
      />
    </div>
  );
};

const useOverviewColumns = ({ hasAnchorsText = false, hasKeywords = false }: { hasAnchorsText?: boolean; hasKeywords?: boolean }) => {
  const selectedScenario = useSelector(selectSelectedScenario);
  const maxGapRatio = useSelector(selectMaxGapRatio);
  const authUser = useSelector(selectAuthUser);

  const closeToGapSort = useCallback(
    (rowA: Row<AnalysisData>, rowB: Row<AnalysisData>, _columnId: string) => {
      const ctgA = selectedScenario?.urls.find((url) => url.url_id === rowA.original.targetPageId)?.close_to_gap;
      const ctgB = selectedScenario?.urls.find((url) => url.url_id === rowB.original.targetPageId)?.close_to_gap;

      return getSortingValue(ctgA, ctgB);
    },
    [selectedScenario?.urls],
  );

  const linksToBuildSort = useCallback(
    (rowA: Row<AnalysisData>, rowB: Row<AnalysisData>, _columnId: string) => {
      const ltbA = getUrlLTB(selectedScenario?.urls.find((url) => url.url_id === rowA.original.targetPageId));
      const ltbB = getUrlLTB(selectedScenario?.urls.find((url) => url.url_id === rowB.original.targetPageId));

      if (ltbA === undefined) {
        return -1;
      }

      if (ltbB === undefined || ltbA > ltbB) {
        return 1;
      }

      if (ltbA < ltbB) {
        return -1;
      }

      return 0;
    },
    [selectedScenario?.urls],
  );

  const dynamicColumns = useMemo(
    () => [
      columnHelper.accessor('closeToGap', {
        header: () => (
          <p className='whitespace-nowrap text-left'>
            Close <br /> the Gap
          </p>
        ),
        cell: ({ row, table }) => <CloseToGapCell scenario={selectedScenario as Scenario} table={table} row={row} hasAnchorsText={hasAnchorsText} />,
        size: 77,
        filterFn: stringFilter,
        sortingFn: closeToGapSort,
      }),
      columnHelper.accessor('scenarioLinksToBuild', {
        size: 80,
        header: () => (
          <p className='whitespace-nowrap text-right'>
            Links <br /> to build
          </p>
        ),
        cell: ({ row, table }) => {
          const ltb = Number(
            getLinksToBuild({
              closeToGap: Number(row.original.closeToGap),
              lrdGap: row.original.lrdGap,
              projLength: selectedScenario?.proj_length,
            }),
          );
          const url = transformCampaignUrlToScenarioUrl({ ...row.original, linksToBuild: row.original.lrdGap ? ltb : 0 });

          return (
            <LinksToBuildField
              scenario={selectedScenario as Scenario}
              table={table as Table<unknown>}
              urlId={row.original.targetPageId}
              url={url}
              hasKeywords={hasKeywords}
              hasAnchorsText={hasAnchorsText}
            />
          );
        },
        filterFn: numberFilter,
        sortingFn: linksToBuildSort,
        sortUndefined: 'last',
      }),
    ],
    [closeToGapSort, hasAnchorsText, hasKeywords, linksToBuildSort, selectedScenario],
  );

  const staticColumns = useMemo(
    () => [
      columnHelper.accessor('totalVolume', {
        size: 1,
        header: () => (
          <p className='text-right'>
            Search <br /> Volume
          </p>
        ),
        cell: ({ getValue, row }) => {
          if (row.original.isFailed || row.original.isEmptyData) {
            return <p className='text-right'>N/A</p>;
          }
          return <p className='text-right'>{getValue().toLocaleString()}</p>;
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('difficulty', {
        size: 1,
        header: () => <p className='text-right'>Difficulty (SEMR)</p>,
        cell: ({ getValue, row }) => <p className=' text-right'>{row.original.isFailed || row.original.isEmptyData ? 'N/A' : round(getValue() || 0 * 100, 2) + '%'}</p>,
        filterFn: numberFilter,
      }),

      columnHelper.accessor('campaignRank', {
        size: 1,
        header: () => <p className='whitespace-nowrap text-right'>Avg Rank</p>,
        cell: ({ getValue, row }) => {
          if (row.original.isFailed || row.original.isEmptyData) {
            return <p className='text-right'>N/A</p>;
          }
          return <p className='text-right'> {round(getValue() || 0, 2)}</p>;
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('pageAuthorityDiff', {
        size: 100,
        header: () => (
          <p className='text-right'>
            Page <br /> Authority
          </p>
        ),
        cell: ({ row: { original, id } }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p>N/A</p>;
          }

          const subjectPA = original.pageAuthority;
          const competitorPA = original.competitor.pageAuthority;

          const tooltipContent = {
            subject: subjectPA,
            competitor: competitorPA,
          };

          return (
            <Tooltip id={`${id}-pa-diff`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Page Authority' />}>
              <TrendCell className='' value={round(subjectPA - competitorPA || 0, 2)} />
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('linksRootDomainDiff', {
        size: 125,
        header: () => (
          <p className='text-right'>
            Links / <br /> Domain
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p>N/A</p>;
          }

          const subjectLRD = round(original.linksRootDomain || 0, 2);
          const competitorLRD = round(original.competitor.linksRootDomain || 0, 2);

          const tooltipContent = {
            subject: subjectLRD,
            competitor: competitorLRD,
          };

          return (
            <Tooltip id={`${id}-lrd`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Links / RD Diff' />}>
              <TrendCell className='' value={round(competitorLRD - subjectLRD || 0, 0)} />
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),
      columnHelper.accessor('lrdGap', {
        size: 75,
        header: () => (
          <p className='text-right'>
            Domain <br /> Gap
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p className='text-right'>N/A</p>;
          }
          const tooltipContent = {
            subject: original.rootDomain,
            competitor: original.competitor.rootDomain,
          };

          return (
            <>
              <Tooltip id={`${id}-lrd-gap`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Root Domain' />}>
                <p className={cn('text-right', original.lrdGap > 0 && 'text-red-500')}>{original.lrdGap > 0 ? Math.ceil(original.lrdGap).toLocaleString() : '-'}</p>
              </Tooltip>
            </>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('volumeGapRatio', {
        size: 60,
        header: () => (
          <p className='text-right'>
            Gap <br /> Index
          </p>
        ),
        cell: ({ getValue, row }) => {
          if (row.original.isFailed || row.original.isEmptyData) {
            return <p className='text-right'>N/A</p>;
          }
          if (row.original.competitor.rootDomain - row.original.rootDomain <= 0) {
            return <p className='text-right'>-</p>;
          }

          const gapIndex = (getValue() / (maxGapRatio || 100)) * 100;
          const roundedGapIndex = Math.ceil(gapIndex);
          return <p className='text-right'>{roundedGapIndex}</p>;
        },
      }),

      columnHelper.accessor('backlinkDADistribution', {
        header: () => <p className='text-left'>Backlink DA Distro</p>,
        cell: ({ getValue, row: { original, id } }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p>N/A</p>;
          }
          const subjectValue = getValue();
          const competitorValue = original.competitor.backlinkDADistribution;

          return (
            <div className='flex items-center'>
              <Tooltip
                id={`${id}-${'subjectBacklinkDADistribution'}`}
                clickable={false}
                place='right'
                content={<BacklinkDADistributionTooltipContent subject={subjectValue} competitor={competitorValue} title='Backlink Domain Authority Distribution' />}
              >
                <div className='flex gap-2'>
                  <div className='flex flex-col text-left'>
                    <p>Subj</p>
                    <p>Comp</p>
                  </div>
                  <div className='flex w-[80px] flex-col'>
                    <ColumnBarChart data={subjectValue} className='w-full' height={6} />
                    <ColumnBarChart data={competitorValue} className='w-full' height={6} />
                  </div>
                </div>
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
      }),

      columnHelper.accessor('ageAverage', {
        size: 30,
        header: () => (
          <p className='whitespace-nowrap text-center'>
            Link Age <br /> (mo. Avg)
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p>N/A</p>;
          }
          const tooltipContent = {
            subject: original.ageAverage,
            competitor: original.competitor.ageAverage,
          };

          const value = Math.ceil(original.ageAverage - original.competitor.ageAverage);

          return (
            <Tooltip id={`${id}-age-avg`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Link Age (months)' />}>
              <p className={cn('text-right text-gray-500', value > 0 && 'text-red-500', value < 0 && 'text-green-500')}>{Math.abs(value).toLocaleString()}</p>
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('velocityDiff.6months', {
        size: 80,
        header: () => (
          <p className='whitespace-nowrap text-right'>
            Velocity <br /> Diff.
          </p>
        ),
        cell: ({ getValue, row }) => {
          if (row.original.isFailed || row.original.isEmptyData) {
            return <p>N/A</p>;
          }
          return (
            <Tooltip
              id={`${row.original.targetPageId}-${'velocity'}`}
              clickable={false}
              place='left'
              content={<VelocityTooltipContent client={row.original.velocity} competitor={row.original.competitor.velocity} title='Velocity' />}
            >
              <p className={cn('text-right text-gray-500', getValue() > 0 && 'text-green-500', getValue() < 0 && 'text-red-500')}>
                {Math.abs(round(getValue() || 0, 0)).toLocaleString()}
              </p>
            </Tooltip>
          );
        },
      }),

      columnHelper.accessor('contextRelevanceScoreAvg', {
        size: 1,
        header: () => <p className='text-right'>Link Relevance</p>,
        cell: ({ row }) => {
          if (row.original.isFailed || row.original.isEmptyData) {
            return <p className='text-right'>N/A</p>;
          }
          const subjectCRS = round(row.original.contextRelevanceScoreAvg || 0, 2);
          const competitorCRS = round(row.original.competitor.contextRelevanceScoreAvg || 0, 2);

          const tooltipContent = {
            subject: subjectCRS,
            competitor: competitorCRS,
          };

          return (
            <Tooltip
              id={`${row.id}-crs-diff`}
              clickable={false}
              place='left'
              content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Link Relevance' countToScore={true} />}
            >
              <p className={cn('text-right text-gray-700', subjectCRS > competitorCRS && 'text-green-500', competitorCRS > subjectCRS && 'text-red-500')}>
                {Math.abs(round(subjectCRS - competitorCRS || 0, 2)).toLocaleString()}
              </p>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor('serpFeaturesByKeyword', {
        header: () => {
          return <p className='text-left'>SERP Features</p>;
        },
        cell: ({ row }) => <SERPFeaturesCell row={row} />,
        enableSorting: false,
      }),
      columnHelper.accessor('anchorTextBuckets', {
        size: 130,
        header: () => <p className='text-left'>Anchor Text Buckets</p>,
        cell: ({ getValue, row: { id, original }, table }) => {
          if (original.isFailed || original.isEmptyData) {
            return <p>N/A</p>;
          }

          const tableMetadata = table.options.meta as TableMetadata;

          const menuItems = [];

          if (authUser?.isSuperuser || authUser?.config.show_linking_context_report) {
            menuItems.push({
              label: 'Create LCR',
              onClick: () => tableMetadata.createLCR(original.url),
              icon: <LCRIcon className='size-6 text-[#64748B]' />,
            });
          }

          const value = getValue();
          const data = Object.keys(value).map((key) => ({
            name: key,
            value: value[key as keyof AnchorTextBuckets],
          }));

          const subjectTotal = Object.values(value).reduce((total, val) => total + val, 0);
          const competitorTotal = Object.values(original.competitor.anchorTextBuckets).reduce((total, val) => total + val, 0);

          const tooltipData = {
            subject: value,
            competitor: original.competitor.anchorTextBuckets,
            subjectTotal: subjectTotal,
            competitorTotal: competitorTotal,
          };

          const renderChartDetail = (key: string, index: number) => {
            if (key === 'other') {
              return;
            }
            const subject = subjectTotal ? (value[key as keyof AnchorTextBuckets] * 100) / subjectTotal : 0;
            const competitor = competitorTotal ? (original.competitor.anchorTextBuckets[key as keyof AnchorTextBuckets] * 100) / competitorTotal : 0;

            const titles: Record<string, string> = {
              exactMatch: 'Exact',
              partialMatch: 'Partial',
              // other: 'Other',
            };

            return (
              <p key={index} className={cn('text-gray-700', subject > competitor && 'text-red-500', competitor > subject && 'text-green-500')}>
                {titles[key]}: {`${Math.abs(round(subject - competitor || 0, 2))}%`}
              </p>
            );
          };

          return (
            <Fragment>
              <OverviewActionsMenu menuItems={menuItems} />
              <div className='w-40'>
                <Tooltip id={`${id}-anchor_text_buckets`} clickable={false} place='left' content={<AnchorTextTooltipContent title='Anchor Text Buckets' data={tooltipData} />}>
                  <ColumnPieChart data={data}>
                    <div className='whitespace-nowrap text-left'>{Object.keys(value).map(renderChartDetail)}</div>
                  </ColumnPieChart>
                </Tooltip>
              </div>
            </Fragment>
          );
        },
        enableSorting: false,
      }),
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [...dynamicColumns, ...staticColumns];
};

export default useOverviewColumns;
