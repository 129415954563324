import { Button, Modal, ModalProps, VirtualizedTable, WarningModal } from '@/components';
import { useTable } from '@/hooks/table';
import { FC, useCallback, useMemo, useState } from 'react';
import useKeywordAnchorTextMatchColumns from './useKeywordAnchorTextMatchColumns';
import { useScreenSize } from '@/hooks/screenSize';
import { SCREEN_SIZES } from '@/constants/layout';
import { useToggle } from '@/hooks/toggle';

type DistributeAnchorsByKeywordModalProps = Omit<ModalProps, 'onConfirm'> & {
  urlKeywords: AnchorTextKeywordState[];
  onReset: () => void;
  onConfirm: (keywords: AnchorTextKeywordState[]) => void;
};

const DistributeAnchorsByKeywordModalFooter = ({
  onConfirm,
  onReset,
  onClose,
  localUrlkeywords,
}: {
  onConfirm: (localUrlkeywords: AnchorTextKeywordState[]) => void;
  onReset: () => void;
  onClose?: () => void;
  localUrlkeywords: AnchorTextKeywordState[];
}) => {
  return (
    <div className='flex items-center justify-end gap-4 border-t-2 px-6 py-3'>
      <Button variant='outline-light' onClick={onClose}>
        Cancel
      </Button>

      <Button variant='outline-light' onClick={onReset}>
        Remove Match By Keywords
      </Button>

      <Button onClick={() => onConfirm && onConfirm(localUrlkeywords)}>Confirm</Button>
    </div>
  );
};

const DistributeAnchorsByKeywordModal: FC<DistributeAnchorsByKeywordModalProps> = ({ isOpen, urlKeywords, onClose, onConfirm, onReset }) => {
  const [localUrlkeywords, setLocalUrlkeywords] = useState(urlKeywords);

  const updater = useCallback(
    (keywordId: number, val: number, field: keyof AnchorTextMatches) => {
      setLocalUrlkeywords((prev) => {
        return prev.map((kw) => {
          if (kw.keyword_id !== keywordId) return kw;
          return { ...kw, [field]: val };
        });
      });
    },
    [setLocalUrlkeywords],
  );

  const { value: warningModalOpen, toggle: toggleWarningModal } = useToggle();

  const isDirty = useMemo(() => {
    return localUrlkeywords.some((kw) => {
      const match = urlKeywords.find((ukw) => ukw.keyword_id === kw.keyword_id);
      return match && (match.exact_match !== kw.exact_match || match.partial_match !== kw.partial_match || match.generic_match !== kw.generic_match);
    });
  }, [localUrlkeywords, urlKeywords]);

  const columns = useKeywordAnchorTextMatchColumns({ isLoading: false });

  const { width } = useScreenSize();

  const modalWidth = width >= SCREEN_SIZES.xxl ? 960 : 840;

  const [table] = useTable({
    data: localUrlkeywords,
    columns,
    tableCustomOptions: ['allowResize'],
    tableOptions: {
      meta: {
        size: modalWidth / columns.length,
        updater,
      },
    },
  });

  const handleOnEscapeKeyUp = () => {
    if (isDirty) {
      return toggleWarningModal(true);
    }

    onClose && onClose();
  };

  const handleWarningModalClose = () => toggleWarningModal(false);

  const handleWarningModalConfirm = () => {
    toggleWarningModal(false);
    onClose && onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onEscapeKeyUp={handleOnEscapeKeyUp}
      onClose={onClose}
      footer={<DistributeAnchorsByKeywordModalFooter onConfirm={onConfirm} onReset={onReset} onClose={onClose} localUrlkeywords={localUrlkeywords} />}
      title='Match Type By Keyword'
      className='max-w-modal-xl'
    >
      <>
        <WarningModal onClose={handleWarningModalClose} onConfirm={handleWarningModalConfirm} isOpen={warningModalOpen} />
        <VirtualizedTable loadingText={'Loading keywords for this URL...'} table={table} name='distributeanchorsByKeyword' allowResize />
      </>
    </Modal>
  );
};

export default DistributeAnchorsByKeywordModal;
