import { FilterFnOption, Row, createColumnHelper } from '@tanstack/react-table';
import Checkbox from '../Checkbox';
import copy from 'copy-to-clipboard';
import { useMemo } from 'react';
import { useGetCampaignCompetitorsDomainsQuery } from '@/store/campaign/campaign.api';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';

const columnHelper = createColumnHelper<CampaignCompetitorDomain>();
const strFilter = 'stringFilter' as FilterFnOption<CampaignCompetitorDomain>;
const numFilter = 'numberFilter' as FilterFnOption<CampaignCompetitorDomain>;

type Meta = {
  setTableChanges: (id: number) => void;
};

const competitorsTableColumns = [
  columnHelper.accessor('id', {
    header: ({ table }) => {
      return (
        <div className='mx-1 flex w-full items-center justify-center'>
          <Checkbox checked={table.getIsAllRowsSelected()} onClick={() => table.toggleAllRowsSelected()} readOnly />
        </div>
      );
    },
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={(e) => {
          (table.options.meta as Meta).setTableChanges(row.original.id);
          row.getToggleSelectedHandler()(e);
        }}
      />
    ),
    size: 50,
    enableResizing: false,
    enableSorting: true,
    sortingFn: (rowA: Row<CampaignCompetitorDomain>, rowB: Row<CampaignCompetitorDomain>) => {
      const a = rowA.getIsSelected();
      const b = rowB.getIsSelected();

      return a === b ? 0 : a ? -1 : 1;
    },
  }),
  columnHelper.accessor('domain', {
    header: () => <p className='text-left'>Domain</p>,
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center gap-2'>
          <p className='overflow-hidden text-ellipsis whitespace-nowrap' title={getValue()}>
            {getValue()}
          </p>
          <button onClick={() => copy(getValue())}>
            <ClipboardDocumentListIcon className='w-5 rounded-full text-gray-500 ' />
          </button>
        </div>
      );
    },
    filterFn: strFilter,
  }),
  columnHelper.accessor('target_pages', { filterFn: 'arrayStrFilter' as FilterFnOption<CampaignCompetitorDomain> }),
  columnHelper.accessor('ranking_pages_number', {
    size: 120,
    header: () => (
      <p className='whitespace-nowrap text-right'>
        Ranking <br /> Pages
      </p>
    ),
    cell: ({ getValue }) => <p className='text-right'>{getValue()}</p>,
    filterFn: numFilter,
  }),
  columnHelper.accessor('number_of_keywords', {
    size: 120,
    header: () => (
      <p className='whitespace-nowrap text-right' title='Number of Keywords'>
        # of KWs
      </p>
    ),
    cell: ({ getValue }) => <p className='w-full text-right'>{getValue().toLocaleString()}</p>,
    filterFn: numFilter,
  }),
  columnHelper.accessor('avg_da', {
    size: 120,
    header: () => (
      <p className='whitespace-nowrap text-right' title='Domain Authority'>
        DA
      </p>
    ),
    cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
    filterFn: numFilter,
  }),
  columnHelper.accessor('avg_pa', {
    size: 120,
    header: () => (
      <p className='whitespace-nowrap text-right' title='Average Page Authority'>
        Avg. PA
      </p>
    ),
    cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
    filterFn: numFilter,
  }),
  columnHelper.accessor('ref_domains', {
    size: 120,
    header: () => (
      <p className='whitespace-nowrap text-right' title='Average Referring Domains'>
        Avg. <br /> R.Domains
      </p>
    ),
    cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
    filterFn: numFilter,
  }),
  columnHelper.accessor('backlinks', {
    size: 200,
    header: () => (
      <p className='whitespace-nowrap text-right'>
        Avg. <br /> Backlinks
      </p>
    ),
    cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
    filterFn: numFilter,
  }),
];

interface CompetitorsTableFilters {
  excluded_domains?: boolean;
  excluded_keywords?: boolean;
  url_id?: string;
}

export const useCompetitorsTableData = ({ campaignId, filters }: { campaignId: string; filters?: CompetitorsTableFilters }) => {
  const { data: competitorsDomains, isLoading: loadingDomains, isFetching } = useGetCampaignCompetitorsDomainsQuery({ campaignId, ...filters });

  const competitorTableData = useMemo(() => {
    if (!competitorsDomains) {
      return [];
    }

    return competitorsDomains.results.slice(1, competitorsDomains.results.length);
  }, [competitorsDomains]);

  const clientExtraHeader = useMemo(() => {
    if (!competitorsDomains) {
      return undefined;
    }

    return competitorsDomains.results[0];
  }, [competitorsDomains]);

  return { competitorsDomains, competitorTableData, clientExtraHeader, loadingDomains, isFetching };
};

export default competitorsTableColumns;
