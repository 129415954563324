import { round } from '@/utils/numbers';
import { FC, useMemo } from 'react';
import { getOverviewTableData } from '@/utils/campaigns';
import AnalysisCard from '../AnalysisCard/AnalysisCard';
import { CHART_COLORS } from '@/utils/table';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import cn from '@/utils/style';
import AnalysisComparisonCard from '../AnalysisComparisonCard/AnalysisComparisonCard';
import { useGetKeywordAnalysisQuery } from '@/store/campaign/campaign.api';
import { useParams } from 'react-router-dom';
import { SERPFeaturesMap } from '@/utils/serpFeatures';
import LinkingContextReportCard from '../LinkingContextReportCard';

export type OverviewTableData = ReturnType<typeof getOverviewTableData>;

interface AnalysisCardListProps {
  analysisData: OverviewTableData[number];
}

interface AnchorTextBucketsAnalysisCardProps {
  subject: AnchorTextBuckets;
  competitor: AnchorTextBuckets;
}

interface VelocityAnalysisCardProps {
  subjectVelocity: Velocity;
  competitorVelocity: Velocity;
  velocityDiff: Velocity;
}

const VelocityAnalysisCard = ({ subjectVelocity, competitorVelocity, velocityDiff }: VelocityAnalysisCardProps) => {
  const data = Object.keys(subjectVelocity).map((key) => ({
    name: key,
    value: subjectVelocity[key as keyof Velocity],
  }));

  const hasData = data.every((item) => item.value !== 0);

  return (
    <AnalysisCard title='Velocity - New Link Discovery' className='h-full w-full text-nowrap p-6'>
      <div className='flex'>
        {hasData && (
          <div className='flex items-center'>
            <ResponsiveContainer width={100} height={80} className=' duration-200 ease-linear hover:scale-110'>
              <PieChart>
                <Pie data={data} innerRadius={25} outerRadius={35} paddingAngle={5} dataKey='value' isAnimationActive={false}>
                  {data.map((item, index) => (
                    <Cell key={`cell-${index}-${item.name}`} fill={CHART_COLORS[index + 2]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
        <div className='w-full'>
          <div className='grid w-full grid-cols-4 place-content-end rounded-sm bg-base-leap py-1 text-base  font-semibold  text-black'>
            <span></span>
            <span className='text-right'>Subject</span>
            <span className='text-right'>Competitor</span>
            <span className='pr-4 text-right'>Diff</span>
          </div>
          <div className='flex w-full text-base'>
            <div className='mt-4 flex w-1/3 flex-col gap-4'>
              <p className=' text-chart-0'>3 Months:</p>
              <p className=' text-chart-1'>6 Months:</p>
            </div>
            <div className='mt-4 flex w-full flex-col gap-4 pr-4 text-black'>
              <div className='grid grid-cols-3 place-content-end'>
                <span className='text-right'>{(subjectVelocity['3months'], 0)}</span>
                <span className=' text-right'>{round(competitorVelocity['3months'], 0)}</span>
                <span className={cn('text-right', velocityDiff['3months'] < 0 ? 'text-red-500' : 'text-green-500')}>{round(velocityDiff['3months'], 0)}</span>
              </div>
              <div className='grid grid-cols-3 place-content-end'>
                <span className='text-right'>{round(subjectVelocity['6months'], 0)}</span>
                <span className='text-right'>{round(competitorVelocity['6months'], 0)}</span>
                <span className={cn('text-right', velocityDiff['6months'] < 0 ? 'text-red-500' : 'text-green-500')}>{round(velocityDiff['6months'], 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnalysisCard>
  );
};

const AnchorTextBucketsAnalysisCard = ({ subject, competitor }: AnchorTextBucketsAnalysisCardProps) => {
  const data = Object.keys(subject).map((key) => ({
    name: key,
    value: subject[key as keyof AnchorTextBuckets],
  }));

  const subjectTotal = Object.values(subject).reduce((total, val) => total + val, 0);
  const competitorTotal = Object.values(competitor).reduce((total, val) => total + val, 0);

  const renderChartDetail = (key: string, index: number) => {
    const subjectData = subjectTotal ? (subject[key as keyof AnchorTextBuckets] * 100) / subjectTotal : 0;
    const competitorData = competitorTotal ? (competitor[key as keyof AnchorTextBuckets] * 100) / competitorTotal : 0;

    const diff = round(competitorData - subjectData, 2);

    const titles: Record<string, string> = {
      exactMatch: 'Exact',
      partialMatch: 'Partial',
      other: 'Other',
    };
    return (
      <div className=' flex w-full gap-4 text-base text-black' key={index}>
        <div className='flex w-1/3 items-center justify-start gap-1'>
          <span style={{ backgroundColor: CHART_COLORS[index] }} className='inline-block size-4 rounded-sm'></span>
          <span>{titles[key]}:</span>
        </div>
        <div className='grid w-full grid-cols-3 place-content-end'>
          <span className='text-right'>{round(subjectData, 2)}%</span>
          <span className='text-right'>{round(competitorData, 2)}%</span>
          <span className={cn('text-right', diff < 0 ? 'text-red-500' : 'text-green-500')}>{Math.abs(diff)}%</span>
        </div>
      </div>
    );
  };

  return (
    <AnalysisCard title='Anchor Text Buckets' className='h-full w-full p-6'>
      <div className='flex w-full items-center gap-1'>
        <ResponsiveContainer width={150} height={140} className='duration-200 ease-linear hover:scale-110'>
          <PieChart>
            <Pie data={data} innerRadius={40} outerRadius={60} paddingAngle={5} dataKey='value' isAnimationActive={false}>
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className='flex w-full flex-col gap-1'>
          <div className=' grid w-full grid-cols-4 place-content-end rounded-sm bg-base-leap py-1 text-base  font-semibold  text-black'>
            <span></span>
            <span className='text-right'>Subject</span>
            <span className='text-right'>Competitor</span>
            <span className='pr-4 text-right'>Diff</span>
          </div>
          <div className='mt-4 flex w-full flex-col gap-4 space-y-2 pr-4'>{Object.keys(subject).map(renderChartDetail)}</div>
        </div>
      </div>
    </AnalysisCard>
  );
};

const AuthorityMetricsAnalysisCard = ({ analysisData }: AnalysisCardListProps) => {
  const {
    pageAuthority,
    domainAuthority,
    pageAuthorityDiff,
    competitor: { pageAuthority: competitorPageAuthority, domainAuthority: competitorDomainAuthority },
  } = analysisData;

  const domainAuthorityDiff = domainAuthority - competitorDomainAuthority;
  return (
    <AnalysisCard title='Authority Metrics' className='h-full w-full text-nowrap p-6 '>
      <div className='flex'>
        <div className='w-full'>
          <div className='grid w-full grid-cols-4 place-content-end rounded-sm bg-base-leap py-1 text-base  font-semibold  text-black'>
            <span className='text-right'>Subject</span>
            <span className='text-right'>Competitor</span>
            <span className='pr-4 text-right'>Diff</span>
          </div>
          <div className='flex w-full text-base'>
            <div className='mt-4 flex w-1/3 flex-col gap-4 font-semibold text-black'>
              <p>Domain</p>
              <p>Page</p>
            </div>
            <div className='mt-4 flex w-full flex-col gap-4 pr-4 text-black'>
              <div className='grid grid-cols-3 place-content-end'>
                <span className='text-right'>{round(domainAuthority, 0)}</span>
                <span className=' text-right'>{round(competitorDomainAuthority, 0)}</span>
                <span className={cn('text-right', domainAuthorityDiff < 0 ? 'text-red-500' : 'text-green-500')}>{round(domainAuthorityDiff, 0)}</span>
              </div>
              <div className='grid grid-cols-3 place-content-end'>
                <span className='text-right'>{round(pageAuthority, 0)}</span>
                <span className='text-right'>{round(competitorPageAuthority, 0)}</span>
                <span className={cn('text-right', pageAuthorityDiff < 0 ? 'text-red-500' : 'text-green-500')}>{round(pageAuthorityDiff, 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnalysisCard>
  );
};

const AllSerpFeaturesList = ({ serpFeaturesList }: { serpFeaturesList: number[] }) => {
  const renderSerpFeatures = (id: number) => {
    const { Icon, name } = SERPFeaturesMap[id];

    return (
      <div key={`${id}-${name}`} className='flex h-10 items-center justify-between px-2'>
        <p>{name}</p>
        <Icon className='size-8 text-slate-400 ' />
      </div>
    );
  };

  return (
    <AnalysisCard className='h-full w-80 ' title='Serp Features'>
      <div className='max-h-80 overflow-auto text-base text-black'>{serpFeaturesList.map(renderSerpFeatures)}</div>
    </AnalysisCard>
  );
};

const AnalysisCardList: FC<AnalysisCardListProps> = ({ analysisData }) => {
  const { campaignId, targetPageId } = useParams() as { campaignId: string; targetPageId: string };
  const subjectLRD = round(analysisData.linksRootDomain, 2);
  const competitorLRD = round(analysisData.competitor.linksRootDomain, 2);
  const LRDdifference = competitorLRD - subjectLRD;

  const subjectCRS = round(analysisData.contextRelevanceScoreAvg, 2);
  const competitorCRS = round(analysisData.competitor.contextRelevanceScoreAvg, 2);
  const CRSDifference = competitorCRS - subjectCRS;

  const { data: kwAnalysis } = useGetKeywordAnalysisQuery({ campaignId, urlId: targetPageId });

  const allSerpFeatures = useMemo(() => {
    if (!kwAnalysis) return [];
    const serpFeatures = kwAnalysis.results.flatMap((res) => res.serpFeaturesByKeyword).filter((feature) => feature !== null);
    return Array.from(new Set(serpFeatures));
  }, [kwAnalysis]);

  return (
    <div>
      <div className='flex w-full gap-6'>
        <div className='flex w-full flex-col gap-6'>
          <div className='flex w-full gap-6'>
            <AnalysisComparisonCard title='Links/Domain' subject={subjectLRD} competitor={competitorLRD} diff={LRDdifference} />
            <AnalysisComparisonCard title='Link Relevance' subject={subjectCRS} competitor={competitorCRS} diff={CRSDifference} />
          </div>
          <div className='flex h-full w-full gap-x-6'>
            <AuthorityMetricsAnalysisCard analysisData={analysisData} />
            {allSerpFeatures.length > 0 && <AllSerpFeaturesList serpFeaturesList={allSerpFeatures} />}
          </div>
        </div>
        <div className='flex w-full flex-col gap-6'>
          <AnchorTextBucketsAnalysisCard subject={analysisData.anchorTextBuckets} competitor={analysisData.competitor.anchorTextBuckets} />
          <VelocityAnalysisCard subjectVelocity={analysisData.velocity} competitorVelocity={analysisData.competitor.velocity} velocityDiff={analysisData.velocityDiff} />
        </div>
      </div>
      <div className='my-6'>
        <LinkingContextReportCard url={analysisData.url} />
      </div>
      <div className='my-6 flex w-full justify-between gap-6'>
        <div className='flex h-fit w-full items-center justify-between gap-2 rounded-leap bg-white p-6'>
          <span className='whitespace-nowrap text-lg text-black 2xl:text-xl'>Total Search Volume</span>
          <span className='text-xl font-bold text-sky-500 2xl:text-2xl'>
            {analysisData.totalVolume.toLocaleString()}
            <span className='ml-2 text-base font-light text-gray-500'>/</span>
            <span className='ml-2 text-base font-light text-gray-500'>Month</span>
          </span>
        </div>
        <div className='flex h-fit w-full items-center justify-between gap-2 rounded-leap bg-white p-6'>
          <span className='whitespace-nowrap text-lg text-black 2xl:text-xl'>Average Search Volume</span>
          <span className='text-xl font-bold text-sky-500 2xl:text-2xl'>
            {Math.ceil(analysisData.avgVolume).toLocaleString()}
            <span className='ml-2 text-base font-light text-gray-500'>/</span>
            <span className='ml-2 text-base font-light text-gray-500'>Month</span>
          </span>
        </div>
        <div className='flex h-fit w-full items-center justify-between gap-2 rounded-leap bg-white p-6'>
          <span className='whitespace-nowrap text-lg text-black 2xl:text-xl'>Keyword Difficulty</span>
          <span className='text-xl font-bold text-sky-500 2xl:text-2xl'>{round(analysisData.difficulty * 100, 0)}%</span>
        </div>
        <div className='flex h-fit w-full items-center justify-between gap-2 rounded-leap bg-white p-6'>
          <span className='whitespace-nowrap text-lg text-black 2xl:text-xl'>Gap</span>
          <span className='text-xl font-bold text-sky-500 2xl:text-2xl'>{round(analysisData.volumeGapRatio * 100, 0)}</span>
        </div>
      </div>
    </div>
  );
};

export default AnalysisCardList;
