import { Avatar, ProgressBar, Tooltip } from '@/components';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useCampaignFetchMutation, useDuplicateCampaignMutation, usePatchCampaignMutation, useUpdateCampaignUsersMutation } from '@/store/campaign/campaign.api';
import { selectCountryList } from '@/store/campaign/campaign.selector';
import { getCountryFlag } from '@/utils/countries';
import { ArrowPathIcon, ExclamationTriangleIcon, UsersIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CollaboratorModal from '../CollaboratorModal';
import cn from '@/utils/style';
import CampaignCardOptions from '../CampaignCardOptions';
import { useToggle } from '@/hooks/toggle';
import EditCampaignNameModal from '../EditCampaignNameModal';
import DeleteCampaignModal from '../DeleteCampaignModal';
import { deserializeCampaignCreator } from '@/store/users/users.api';

interface CampaignCardProps {
  campaign: CampaignJSON;
  onClick: () => void;
  index: number;
}

type UrlOrKeyword = 'urls' | 'keywords';

const RUN_STATUS_COLOR: Record<RunStatus, string> = {
  done: '',
  error: 'bg-red-400',
  in_progress: 'bg-yellow-300',
  queued: 'bg-gray-100',
  fetching_backlinks: 'bg-gray-100',
  fetching_brand_names: 'bg-gray-100',
  fetching_keywords: 'bg-gray-100',
  fetching_metrics: 'bg-gray-100',
  fetching_velocities: 'bg-gray-100',
  running_calculations: 'bg-gray-100',
};

const CAMPAIGN_STATUS_COLOR: Record<AnalysisStep, string> = {
  analysis: 'bg-teal-100',
  'scenario-review': 'bg-cyan-100',
  'anchor-text-generator': 'bg-blue-100',
  summary: 'bg-gray-100',
};

const analysisStepMapper: Record<AnalysisStep, string> = {
  analysis: 'LINK GAP ANALYSIS',
  'scenario-review': 'PROPOSAL REVIEW',
  'anchor-text-generator': 'ANCHOR TEXT GENERATOR',
  summary: 'FINAL SUMMARY',
};

const runStatusMapper: Record<RunStatus, string> = {
  done: 'DONE',
  error: 'ERROR',
  in_progress: 'IN PROGRESS',
  queued: 'QUEUED',
  fetching_backlinks: 'FETCHING BACKLINKS',
  fetching_brand_names: 'FETCHING BRAND NAMES',
  fetching_keywords: 'FETCHING KEYWORDS',
  fetching_metrics: 'FETCHING METRICS',
  fetching_velocities: 'FETCHING VELOCITIES',
  running_calculations: 'RUNNING CALCULATIONS',
};

const CampaignCard: FC<CampaignCardProps> = ({ campaign, onClick, index }) => {
  const lastRun = campaign.last_run;

  const countries = useSelector(selectCountryList);
  const user = useSelector(selectAuthUser);
  const hasFailedKwsOrUrls = lastRun && (lastRun.failed_keywords.length !== 0 || lastRun.failed_urls.length !== 0);
  const [updateCampaign] = usePatchCampaignMutation();
  const [duplicateCampaign] = useDuplicateCampaignMutation();
  const [campaignFetch, { isLoading }] = useCampaignFetchMutation();
  const [updateCampaignUsers] = useUpdateCampaignUsersMutation();
  const [collaborationModalOpen, setCollaborationModalOpen] = useState(false);

  const isOwnerOrSuperAdmin = useMemo(() => {
    const campaignUserRole = (user?.campaigns || []).find((camp) => camp.id === campaign.id)?.role;

    return campaignUserRole === 'owner' || campaignUserRole === 'creator' || user?.isSuperuser;
  }, [user, campaign.id]);

  const getCountryValue = useCallback(
    (countryCode: string) => {
      const country = countries.find((item) => item.key === countryCode);
      return country && getCountryFlag(country);
    },
    [countries],
  );

  const runStatusColorClass = useMemo(() => {
    if (!lastRun) {
      return '';
    }

    return RUN_STATUS_COLOR[lastRun.status];
  }, [lastRun]);

  const campaignStatusColorClass = useMemo(() => {
    if (campaign.status === 'kicked_off') {
      return 'bg-emerald-100';
    }

    if (campaign.config && campaign.config.user_progress) {
      return CAMPAIGN_STATUS_COLOR[campaign.config.user_progress.last_step || 'analysis'];
    }

    return 'bg-gray-200';
  }, [campaign]);

  const campaignStatusText = useMemo(() => {
    if (campaign.status === 'kicked_off') {
      return 'KICKED OFF';
    }

    if (campaign.config && campaign.config.user_progress) {
      return analysisStepMapper[campaign.config.user_progress.last_step || 'analysis'];
    }

    return 'NEW';
  }, [campaign]);

  const renderTooltip = (field: UrlOrKeyword) => {
    if (!lastRun) {
      return null;
    }

    const fieldMap = {
      keywords: lastRun.failed_keywords,
      urls: lastRun.failed_urls,
    };

    const tooltipContent = (
      <ol className='text-left'>
        {fieldMap[field].map((entry, index) => {
          const [urlKeyword, reason] = entry;

          return (
            <li key={index}>
              <b>{field === 'keywords' ? 'Keyword: ' : 'URL: '}</b>
              {urlKeyword} <b>Reason: </b>
              {reason}
            </li>
          );
        })}
      </ol>
    );

    return (
      <Tooltip id={`${(lastRun || {}).id}-failed-${field}`} place='top' content={tooltipContent}>
        <b className='text-sm'>{field === 'keywords' ? 'Keywords:' : 'URLs:'}</b>
        <span className='text-semibold text-sm text-red-600 xl:text-base'> {fieldMap[field].length}</span>
      </Tooltip>
    );
  };

  const fetchStatusText = useMemo(() => {
    if (lastRun && lastRun.status && !['done', 'error'].includes(lastRun.status)) {
      return runStatusMapper[lastRun.status];
    }

    return 'Fetch Status';
  }, [lastRun]);

  const statusText = useMemo(() => {
    if (!lastRun) {
      return '?';
    }

    if (lastRun.status === 'error') {
      return 'Error fetching';
    }

    if (lastRun.fetch_progress === 1 || lastRun.status === 'done') {
      return 'Complete';
    }

    return `${Math.round(lastRun.fetch_progress * 100)}%`;
  }, [lastRun]);

  const campaignUrls = useMemo(() => (campaign.urls ? campaign.urls : []), [campaign]);

  const totalKeywords = useMemo(() => {
    return campaignUrls.reduce((acc, url) => acc + url.keywords.length, 0);
  }, [campaignUrls]);

  const handleRetryClick = useCallback(async () => {
    await campaignFetch({ campaign }).unwrap();
  }, [campaignFetch, campaign]);

  const handleCollaboratorConfirm = async (collaborators: Array<CampaignUser>) => {
    await updateCampaignUsers({ campaignId: campaign.id, users: collaborators }).unwrap();
    setCollaborationModalOpen(false);
  };

  const handleArchive = useCallback(async () => {
    await updateCampaign({ campaignId: campaign.id.toString(), data: { is_archived: !campaign.is_archived } });
  }, [campaign.id, campaign.is_archived, updateCampaign]);

  const handleDuplicate = useCallback(async () => {
    await duplicateCampaign({ campaignId: campaign.id.toString() });
  }, [campaign.id, duplicateCampaign]);

  const { value: isEditCampaignModalOpen, toggle: toggleEditCampaignModal } = useToggle();
  const { value: isRemoveCampaignModalOpen, toggle: toggleRemoveCampaignModal } = useToggle();

  const campaignCardOptionsActions = useMemo(() => {
    return {
      onEdit: () => toggleEditCampaignModal(true),
      onDuplicate: handleDuplicate,
      onArchive: handleArchive,
      onRemove: () => toggleRemoveCampaignModal(true),
    };
  }, [toggleEditCampaignModal, toggleRemoveCampaignModal, handleArchive, handleDuplicate]);

  const handleEditCompanyModalClose = useCallback(() => {
    toggleEditCampaignModal(false);
  }, [toggleEditCampaignModal]);

  return (
    <Fragment>
      <DeleteCampaignModal isOpen={isRemoveCampaignModalOpen} campaignId={campaign.id} onClose={() => toggleRemoveCampaignModal(false)} />
      <EditCampaignNameModal isOpen={isEditCampaignModalOpen} campaignId={campaign.id} initialValue={campaign.name} onClose={handleEditCompanyModalClose} />

      <div className='grid w-full grid-cols-12'>
        <section className={cn('col-span-5 flex flex-col gap-1 text-left', lastRun && lastRun.status === 'done' && 'cursor-pointer')} onClick={onClick}>
          <div className='flex items-center text-lg capitalize'>
            <span className='mb-1.5 font-bold'>{campaign.name} </span>
            <span className='mb-1.5 ml-2'>
              {getCountryValue(campaign.country || '')} {(campaign.country || '').toUpperCase()}
            </span>
            <div onClick={(e) => e.stopPropagation()}>
              <CampaignCardOptions actions={campaignCardOptionsActions} isArchived={campaign.is_archived} />
            </div>
          </div>

          <div className='flex w-full items-start gap-4'>
            <div className='grid w-1/2 grid-cols-3 gap-y-4 text-xs md:text-sm'>
              <p className='text-sm font-semibold capitalize text-gray-500'>Creator:</p>
              <div className='flex-center relative col-span-2 flex items-center gap-1 pl-10'>
                <Avatar user={deserializeCampaignCreator(campaign.creator)} displayName={false} containerClassName='absolute -left-0' />
                <p className='truncate'>{`${campaign.creator.first_name} ${campaign.creator.last_name}  `}</p>
              </div>
              <p className='text-sm font-semibold capitalize text-gray-500'>Created On:</p>
              <span className='col-span-2'>{new Date(campaign.created_at).toLocaleDateString()}</span>
              <p className='text-sm font-semibold capitalize text-gray-500'>Budget:</p>
              <span className='col-span-2'>{campaign.budget ? `${campaign.budget} /mo` : '---'}</span>
            </div>

            <div className='h-full w-0.5 rounded-md bg-gray-200' />

            <div className='grid w-1/2 grid-cols-3 gap-y-4'>
              <p className='col-span-2 text-sm font-semibold capitalize text-gray-500'>Total URLs:</p>
              <span>{campaignUrls.length}</span>
              <p className='col-span-2 text-sm font-semibold capitalize text-gray-500'>Total Keywords:</p>
              <span>{totalKeywords}</span>
            </div>
          </div>
        </section>

        <section className={cn('col-span-2 flex w-full flex-col items-end justify-center gap-1 pr-6 text-right', hasFailedKwsOrUrls && 'border-r border-gray-300')}>
          {hasFailedKwsOrUrls && (
            <Fragment>
              <div className='flex flex-row flex-nowrap items-center justify-end gap-2 text-red-600'>
                <ExclamationTriangleIcon className='w-5' />
                <p className='whitespace-nowrap text-sm font-semibold'>Failed URLs/Keywords</p>
              </div>
              {lastRun && lastRun.failed_urls.length !== 0 && renderTooltip('urls')}
              {lastRun && lastRun.failed_keywords.length !== 0 && renderTooltip('keywords')}
            </Fragment>
          )}
        </section>

        <section className='col-span-3 flex w-full flex-col flex-nowrap items-end justify-center gap-3 border-r border-gray-300 pr-4'>
          <div className='flex w-full items-center justify-end'>
            {lastRun && lastRun.status === 'error' && (
              <div onClick={handleRetryClick} className='mr-auto flex cursor-pointer items-center text-blue-500 '>
                <ArrowPathIcon className={cn('mx-2 w-5', isLoading && 'animate-spin')} />
                <p className='font-bold'>Retry</p>
              </div>
            )}
            <p className={cn('rounded-lg px-3 py-1 text-sm font-semibold', lastRun && lastRun.status === 'error' ? 'text-white' : 'text-gray-500', runStatusColorClass)}>
              {fetchStatusText}
            </p>
          </div>
          <ProgressBar
            progress={lastRun && lastRun.status === 'done' ? 100 : (lastRun?.fetch_progress || 0) * 100}
            showProgress={false}
            color={cn(lastRun && lastRun.status === 'error' ? 'bg-red-400' : 'bg-green-700')}
            className='mb-px mt-0 w-full pl-4'
          />
          <p className='px-3 text-sm font-semibold'>{statusText}</p>
        </section>

        <section className={cn('col-span-2 flex w-full flex-col items-end justify-center gap-2 px-4', isOwnerOrSuperAdmin && 'border-r border-gray-300')}>
          <p className='text-sm font-semibold text-gray-500'>Campaign Status</p>
          <p className={cn('rounded-lg px-3 py-1 text-xs', campaignStatusColorClass)}>{campaignStatusText}</p>
        </section>
      </div>

      {isOwnerOrSuperAdmin && (
        <section className='grid place-content-center pl-2'>
          <UsersIcon
            id={index === 0 ? 'tutorial-cards-1' : ''}
            className={cn('w-6', lastRun && lastRun.status === 'error' ? 'cursor-not-allowed text-red-400' : 'cursor-pointer text-gray-400')}
            onClick={lastRun && lastRun.status === 'error' ? undefined : () => setCollaborationModalOpen(true)}
          />
        </section>
      )}

      {collaborationModalOpen && (
        <CollaboratorModal isOpen={collaborationModalOpen} onClose={() => setCollaborationModalOpen(false)} onConfirm={handleCollaboratorConfirm} campaignId={campaign.id} />
      )}
    </Fragment>
  );
};

export default CampaignCard;
