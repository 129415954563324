import { FC, Fragment } from 'react';
import CollapsableCard from '../CollapsableCards/CollapsableCard';
import { Button, Loading, SeparatorLine, ToastCard } from '@/components';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useRetryURLSummaryMutation } from '@/store/poc/poc.api';
import { toast } from 'react-toastify';
import { isLCRAvailableToRetry } from '@/utils/lcr';
import { useToggle } from '@/hooks/toggle';

interface SummaryCardProps {
  summary: LCRSummary;
}

const SummaryCardLoading: FC = () => (
  <div className='flex w-full items-center justify-center gap-2'>
    <Loading className='size-6' />
    <h4>Summary is being generated...</h4>
  </div>
);

const SummaryCardContent: FC<{ summary: LCRSummary }> = ({ summary }) => {
  const [retryURLSummary, { isLoading }] = useRetryURLSummaryMutation();

  const handleRetrySummary = async () => {
    await retryURLSummary(summary.id);
    toast(<ToastCard message='Summary retryed' />, { type: 'success', autoClose: 2000 });
  };

  if (['in_progress', 'created'].includes(summary.status)) {
    return <SummaryCardLoading />;
  }

  if (summary.status === 'error') {
    return (
      <div className='flex items-center justify-center gap-2'>
        <ExclamationTriangleIcon className='size-8 text-red-500' />
        <h4>An unanticipated error occurred while creating a summary...</h4>
        {isLCRAvailableToRetry(summary.status, summary.message) && (
          <Button
            className='bg-transparent px-0 text-red-500 hover:bg-transparent hover:text-red-700 active:bg-transparent disabled:border-none disabled:bg-transparent'
            isLoading={isLoading}
            onClick={handleRetrySummary}
          >
            Try again
          </Button>
        )}
      </div>
    );
  }

  return <h4>Summary</h4>;
};

const SummaryCard: FC<SummaryCardProps> = ({ summary }) => {
  const { value, toggle } = useToggle();

  return (
    <Fragment>
      <div className='p-6'>
        <CollapsableCard
          content={<SummaryCardContent summary={summary} />}
          containerClass='bg-white mt-0'
          disabled={['in_progress', 'created', 'error'].includes(summary.status)}
          isOpen={value}
          onToggle={() => toggle()}
        >
          <h5 className='my-4'>Themes</h5>
          <div className='max-h-[12rem] overflow-y-auto'>
            {summary.themes.map(({ theme, description, id }) => (
              <div className='mb-4 flex justify-between gap-2 align-baseline' key={`theme-${theme}-${id}`}>
                <span className='h-3 w-3 rounded-full bg-sky-600'></span>
                <div className='w-full'>
                  <h5>{theme}</h5>
                  <p className='text-slate-500'>{description}</p>
                </div>
              </div>
            ))}
          </div>
          <SeparatorLine />
          <h5 className='my-4'>Outliers</h5>
          <div className='max-h-[12rem] overflow-y-auto'>
            {summary.outliers.map(({ theme, description, id }) => (
              <div className='mb-4 flex justify-between gap-2 align-baseline' key={`outlier-${theme}-${id}`}>
                <span className='h-3 w-3 rounded-full bg-sky-600'></span>
                <div className='w-full'>
                  <h5>{theme}</h5>
                  <p className='text-slate-500'>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </CollapsableCard>
      </div>
      <SeparatorLine />
    </Fragment>
  );
};

export default SummaryCard;
