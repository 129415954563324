import { FC } from 'react';
import { Button, Label, Modal, TextInputField, ToastCard } from '@/components';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useAddTargetAudiencesMutation } from '@/store/poc/poc.api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface AddTargetAudienceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const addTargetAudienceValidationSchema: yup.SchemaOf<{ target_audiences: string[] }> = yup.object({
  target_audiences: yup.array().of(yup.string().required('Audience name is required')),
});

const AddTargetAudienceModal: FC<AddTargetAudienceModalProps> = ({ isOpen, onClose }) => {
  const [addTargetAudienceToURL, { isLoading, error }] = useAddTargetAudiencesMutation();
  const { urlId } = useParams() as { urlId: string };
  const createError = error as APIError;

  const formik = useFormik({
    onSubmit: async (values, actions) => {
      const { target_audiences } = values;

      const capitalizedTargetAudience = target_audiences.map((ta) => {
        const words = ta.split(' ');

        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
      });

      try {
        await addTargetAudienceToURL({ urlIdOrHash: urlId, target_audiences: capitalizedTargetAudience });
        toast(<ToastCard message='Target Audiences created' />, { type: 'success', autoClose: 2000 });
        actions.resetForm();
      } catch {
        toast(<ToastCard message={`Something went wrong, ${createError?.status} - ${createError?.data.message}`} />, { type: 'error', autoClose: 2000 });
      }
    },
    initialValues: {
      target_audiences: [''],
    },
    validationSchema: addTargetAudienceValidationSchema,
  });

  const deleteTargetAudience = (index: number) => {
    const targetAudiences = formik.values.target_audiences;

    if (targetAudiences.length === 1) {
      return;
    }

    targetAudiences.splice(index, 1);

    return formik.setFieldValue('target_audiences', targetAudiences);
  };

  const addTargetAudience = () => {
    return formik.setFieldValue('target_audiences', [...formik.values.target_audiences, '']);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Add Target Audience' dialogPanelclassName='w-modal-xl'>
      <FormikProvider value={formik}>
        <Form>
          {formik.values.target_audiences.map((_, index) => (
            <div key={`target_audiences[${index}]`}>
              <Label>Audience name</Label>
              <div className='my-2 flex items-start gap-2'>
                <TextInputField name={`target_audiences[${index}]`} />
                <button type='button' className='rounded-leap border p-2 text-gray-400 hover:text-black' onClick={() => deleteTargetAudience(index)}>
                  <TrashIcon className='size-6' />
                </button>
              </div>
            </div>
          ))}
          <button type='button' className='my-4 flex items-center font-bold text-sky-600' onClick={addTargetAudience}>
            <PlusIcon className='size-6' /> Add Another
          </button>
          <Button type='submit' className='w-full' isLoading={isLoading}>
            Add Target Audience
          </Button>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default AddTargetAudienceModal;
