import { INITIAL_ORDERING, INITIAL_PAGE, INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { getDateWithDefaultFormat } from '@/utils/dates';
import api from '../api';
import { getStateItemUpdater } from '@/utils/arrayStateItem';
import BugsnagPerformance from '@bugsnag/browser-performance';

const deserializeCampaignCreator = (user: Omit<UserJSON, 'campaigns'>): Omit<User, 'campaigns'> => ({
  id: user.id,
  email: user.email,
  fullName: `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.email.split('@').shift() || '?',
  isActive: user.is_active,
  isSSO: user.is_sso,
  dateJoined: user.date_joined ? getDateWithDefaultFormat(user.date_joined) : null,
  lastLogin: user.last_login ? getDateWithDefaultFormat(user.last_login) : null,
  role: user.role,
  company: user.company,
  profilePictureUrl: user.profile_picture_url,
  isSuperuser: user.is_superuser,
  isCompanyOwner: Boolean(user.company && user.company.role === 'owner'),
  firstName: user.first_name,
  lastName: user.last_name,
  config: user.config,
});

const deserializeUser = (user: UserJSON): User => ({
  id: user.id,
  email: user.email,
  fullName: `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.email.split('@').shift() || '?',
  isActive: user.is_active,
  isSSO: user.is_sso,
  dateJoined: user.date_joined ? getDateWithDefaultFormat(user.date_joined) : null,
  lastLogin: user.last_login ? getDateWithDefaultFormat(user.last_login) : null,
  role: user.role,
  company: user.company,
  profilePictureUrl: user.profile_picture_url,
  isSuperuser: user.is_superuser,
  isCompanyOwner: Boolean(user.company && user.company.role === 'owner'),
  campaigns: user.campaigns,
  firstName: user.first_name,
  lastName: user.last_name,
  config: user.config,
});

const usersAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getUser: build.query<User, { id: string }>({
      query: ({ id }) => ({
        method: 'GET',
        url: `users/${id}/`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getUser');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      transformResponse: (data: UserJSON) => deserializeUser(data),
    }),
    updateUser: build.mutation<APIResponse<User>, Partial<UpdateUserArgs>>({
      query: (data) => ({
        method: 'PATCH',
        url: `users/${data.id}/`,
        body: data,
      }),
      transformResponse: (response: UserJSON) => ({ data: deserializeUser(response) }),
      invalidatesTags: ['usersList', 'currentUser', 'companiesList'],
    }),
    deleteUser: build.mutation<GenericOKResponse, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `users/${id}/`,
      }),
      invalidatesTags: ['usersList', 'companiesList'],
    }),
    changePassword: build.mutation<APIResponse<User>, ChangePasswordFormValues>({
      query: (data) => ({
        method: 'POST',
        url: 'users/change-password/',
        body: data,
      }),
    }),
    forgotPassword: build.mutation<GenericOKResponse, ForgotPasswordValues>({
      query: (data) => ({
        method: 'POST',
        url: 'users/forgot-password/',
        body: data,
      }),
    }),
    resetPassword: build.mutation<GenericOKResponse, ResetPasswordValues>({
      query: (data) => ({
        method: 'POST',
        url: 'users/reset-password/',
        body: data,
      }),
    }),
    usersList: build.query<Page<User>, UserAPIArgs>({
      query: ({ page = INITIAL_PAGE, page_size = INITIAL_PAGE_SIZE, search = '', ordering, filter }) => ({
        method: 'GET',
        url: 'users/',
        params: {
          page,
          page_size,
          search,
          ordering,
          role: filter?.role,
          campaign_id: filter?.campaignId,
        },
      }),
      transformResponse: (data: Page<UserJSON>) => {
        const users = data.results.map(deserializeUser);

        return { ...data, results: users };
      },
      merge: (currentCache, newItems, { arg }) => {
        if ((arg.search !== '' && arg.page === 1) || (arg.ordering !== '' && arg.page === 1)) {
          currentCache.results = [];
        }

        for (const result of newItems.results) {
          currentCache.results = getStateItemUpdater(result, (a, b) => a.id === b.id)(currentCache.results);
        }
        currentCache.next = newItems.next;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
      providesTags: () => ['usersList'],
    }),
    addUser: build.mutation<UserJSON, NewUserValues>({
      query: (data) => ({
        method: 'POST',
        url: 'users/',
        body: data,
      }),
      invalidatesTags: ['usersList', 'companiesList'],
    }),
    resendInvite: build.mutation<GenericOKResponse, { id: string }>({
      query: ({ id }) => ({
        method: 'POST',
        url: `users/${id}/resend-invite/`,
      }),
    }),
    markNotificationAsRead: build.mutation<APIResponse<LeapNotification>, { notification: LeapNotification }>({
      query: ({ notification }) => {
        return {
          method: 'PATCH',
          url: `notifications/${notification.id}`,
          body: { read_at: new Date().toISOString() },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;

          dispatch(
            usersAPI.util.updateQueryData('getUserNotifications', {}, (currentCache) => {
              return { ...currentCache, results: getStateItemUpdater(data, (a, b) => a.id === b.id)(currentCache.results) };
            }),
          );
        } catch {
          dispatch(api.util.invalidateTags(['userNotifications']));
        }
      },
    }),
    getUserNotifications: build.query<Page<LeapNotification>, ExtraFilters>({
      query: ({ page = INITIAL_PAGE, page_size = 15, sort = INITIAL_ORDERING, ...rest }) => {
        const ordering = sort.length === 0 ? [INITIAL_ORDERING] : sort;

        return {
          method: 'GET',
          url: 'users/me/notifications/',
          params: {
            page: page,
            page_size: page_size,
            ordering: ordering,
            ...rest,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge: (currentCache, newItems) => {
        for (const result of newItems.results) {
          currentCache.results = getStateItemUpdater(result, (a, b) => a.id === b.id)(currentCache.results);
        }
        currentCache.next = newItems.next;
      },
      providesTags: () => ['userNotifications'],
    }),
    getCurrentUser: build.query<User, void>({
      query: () => ({
        method: 'GET',
        url: 'users/me/',
      }),
      transformResponse: (data: UserJSON) => deserializeUser(data),
      providesTags: () => ['currentUser'],
    }),
    updateUserConfig: build.mutation<APIResponse<UserConfig>, { last_edited_campaign: number }>({
      query: (userConfig) => {
        return {
          method: 'PATCH',
          url: 'users/me/',
          body: userConfig,
        };
      },
    }),
    removeUserPicture: build.mutation<User, void>({
      query: () => ({
        method: 'DELETE',
        url: 'users/profile-picture/',
      }),
      invalidatesTags: ['currentUser'],
    }),
    createUserFeedback: build.mutation<APIResponse<GenericOKResponse>, FeedbackFormValues>({
      query: (data) => ({
        method: 'POST',
        url: 'feedback/',
        body: data,
      }),
    }),
    betaAccessRequests: build.query<APIListResponse<BetaAccessUser>, void>({
      query: () => ({
        method: 'GET',
        url: 'beta-access-requests/',
      }),
      providesTags: ['betaAccessRequests'],
    }),
    deleteBetaAccessRequest: build.mutation<APIResponse<GenericOKResponse>, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `beta-access-requests/${id}/`,
      }),
      invalidatesTags: ['betaAccessRequests'],
    }),
  }),
});

const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useUsersListQuery,
  useLazyUsersListQuery,
  useAddUserMutation,
  useResendInviteMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useGetUserNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useLazyGetUserNotificationsQuery,
  useUpdateUserConfigMutation,
  useCreateUserFeedbackMutation,
  useRemoveUserPictureMutation,
  useBetaAccessRequestsQuery,
  useDeleteBetaAccessRequestMutation,
} = usersAPI;

export {
  deserializeCampaignCreator,
  deserializeUser,
  useAddUserMutation,
  useResendInviteMutation,
  useCreateUserFeedbackMutation,
  useDeleteUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useGetCurrentUserQuery,
  useGetUserNotificationsQuery,
  useGetUserQuery,
  useLazyGetCurrentUserQuery,
  useLazyGetUserNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useResetPasswordMutation,
  useUpdateUserConfigMutation,
  useUpdateUserMutation,
  useUsersListQuery,
  useLazyUsersListQuery,
  useRemoveUserPictureMutation,
  useBetaAccessRequestsQuery,
  useDeleteBetaAccessRequestMutation,
};

export default usersAPI;
