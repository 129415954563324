import Modal from '../Modal';

interface WarningModalProps {
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const WarningModal = ({ message = 'Are you sure you want to leave current selection? All progress will be lost', onClose, isOpen, onConfirm }: WarningModalProps) => {
  return (
    <Modal isOpen={isOpen} title='Warning!' onClose={onClose} onConfirm={onConfirm}>
      <p>{message}</p>
    </Modal>
  );
};

export default WarningModal;
