import { useCallback, useMemo, useState } from 'react';
import { useScreenSize } from './screenSize';
import { getUrlMaxLength } from '@/utils/table';
import { useTable } from './table';
import { ColumnDef } from '@tanstack/react-table';

const useSelectUrls = () => {
  const [selectedUrls, setSelectedUrls] = useState<Array<string>>([]);
  const toggleSelectedUrl = useCallback((url: string) => {
    setSelectedUrls((selectedUrls) => {
      if (selectedUrls.includes(url)) {
        return selectedUrls.filter((i) => i !== url);
      }

      return [...selectedUrls, url];
    });
  }, []);

  const toggleAll = useCallback((tableUrls: Array<string>) => {
    setSelectedUrls((selectedUrls) => {
      if (tableUrls.every((i) => selectedUrls.includes(i))) {
        return selectedUrls.filter((i) => !tableUrls.includes(i));
      }

      return [...selectedUrls.filter((i) => !tableUrls.includes(i)), ...tableUrls];
    });
  }, []);

  return [selectedUrls, toggleSelectedUrl, toggleAll] as const;
};

const useOrganicResearchTable = (data: OrganicResearch[], columns: ColumnDef<OrganicResearch, any>[]) => {
  const { width } = useScreenSize();

  const maxTargetUrlColumnSize = useMemo(() => {
    if (!data) return 800;

    const maxUrlLength = Math.max(...data.map(({ url }) => url.length));

    return getUrlMaxLength(maxUrlLength);
  }, [data]);

  const tableMeta = useMemo(() => {
    return {
      size: (width * 0.753) / columns.length,
      columns: {
        url: { maxSize: maxTargetUrlColumnSize },
      },
    };
  }, [maxTargetUrlColumnSize, columns.length, width]);

  const [table, flatRows] = useTable({
    data,
    columns,
    tableCustomOptions: ['allowResize', 'allowSelect', 'allowSort', 'allowExpand'],
    tableOptions: {
      meta: tableMeta,
      state: {
        columnVisibility: {
          select: data.length > 0,
        },
      },
    },
  });

  return [table, flatRows, width] as const;
};

export { useSelectUrls, useOrganicResearchTable };
