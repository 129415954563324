import { ChartTooltipContent, Checkbox, ColumnBarChart, Tooltip } from '@/components';
import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { PlayIcon } from '@heroicons/react/24/solid';
import { FilterFnOption, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

const bucketKeyToTitle: Record<keyof IntentsType, string> = {
  commercialPosition: 'Intent Commercial Positions',
  informationalPosition: 'Intent Informational Positions',
  navigationalPosition: 'Intent Navigational Positions',
  transactionalPosition: 'Intent Transactional Positions',
};

const columnHelper = createColumnHelper<OrganicResearch>();

const useOrganicResearchColumns = ({
  selectedUrls,
  toggleSelectedUrl,
  toggleAll,
}: {
  selectedUrls: Array<string>;
  toggleSelectedUrl: (url: string) => void;
  toggleAll: (url: Array<string>) => void;
}) => {
  const stringFilter = 'stringFilter' as FilterFnOption<OrganicResearch>;
  const numberFilter = 'numberFilter' as FilterFnOption<OrganicResearch>;

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            className='mx-auto'
            checked={table.getRowModel().rows.every((row) => selectedUrls.some((i: string) => row.original.url === i))}
            onChange={() => toggleAll(table.getRowModel().rows.map(({ original: { url } }) => url))}
          />
        ),
        cell: ({
          row: {
            original: { url },
          },
        }) => <Checkbox checked={selectedUrls.includes(url)} onChange={() => toggleSelectedUrl(url)} />,
        size: 45,
        enableSorting: false,
      }),
      columnHelper.accessor('url', {
        header: () => <p>Target URL</p>,
        cell: ({ getValue }) => (
          <p className='line-clamp-1 text-ellipsis break-all' title={getValue()}>
            {getValue()}
          </p>
        ),
        filterFn: stringFilter,
        size: 500,
      }),
      columnHelper.accessor('traffic', {
        header: () => <p className='text-right'>Traffic</p>,
        cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('totalIntents', {
        header: () => <p>KWs Count</p>,
        cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.display({
        id: 'transcommKwCountTraffic',
        header: () => (
          <p className='whitespace-nowrap text-left'>
            Transcomm
            <br />
            KW count / Traffic
          </p>
        ),
        cell: ({ row }) => {
          if (!row.original.totalIntents) {
            return <p className='text-right'>?</p>;
          }

          const { transactionalPosition, commercialPosition } = row.original.intents;
          const transcomm = transactionalPosition + commercialPosition;
          const transcommPercentage = round((transcomm / row.original.totalIntents) * 100, 0);

          return <p className='text-right'>{`${transcomm} / ${transcommPercentage}%`}</p>;
        },
        filterFn: numberFilter,
      }),
      columnHelper.accessor('intents', {
        header: () => <p className='text-left'>Intent</p>,
        cell: ({ getValue, row }) => {
          const value = getValue();

          const tooltipData = Object.keys(value).reduce((prev, key) => {
            const typedKey = key as keyof IntentsType;
            return {
              ...prev,
              [bucketKeyToTitle[typedKey]]: value[typedKey],
            };
          }, {});

          return (
            <Tooltip id={`intents-${row.id}`} content={<ChartTooltipContent data={tooltipData} title='Keywords by Intents' />}>
              <ColumnBarChart data={getValue()} className='justify-between' height={5} />
            </Tooltip>
          );
        },
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'expand',
        header: () => null,
        cell: ({ row, table }) => {
          const handleRowExpansion = () => {
            const wasExpanded = row.getIsExpanded();
            table.toggleAllRowsExpanded(false);
            row.toggleExpanded(!wasExpanded);
          };

          return (
            <button className='cursor-pointer bg-slate-100 p-2' onClick={handleRowExpansion}>
              <PlayIcon className={cn('size-3 rotate-90 text-sky-600', row.getIsExpanded() && '-rotate-90')} />
            </button>
          );
        },
        size: 45,
      }),
    ],
    [selectedUrls, toggleSelectedUrl, toggleAll],
  );

  return columns;
};

export default useOrganicResearchColumns;
