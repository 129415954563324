import { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { Button, Modal, ToastCard } from '@/components';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import CollapsableCard from '../CollapsableCards/CollapsableCard';
import { useParams } from 'react-router-dom';
import { useCreateURLSummaryMutation, useGetURLSummaryQuery } from '@/store/poc/poc.api';
import SummaryCard from '../SummaryCard';
import { toast } from 'react-toastify';
import { useCollapsable } from '@/hooks/collapsable';

interface TargetAudienceModalProps {
  isOpen: boolean;
  onClose: () => void;
  audiences: URLTargetAudience[];
}

interface ModalFooterProps {
  allowCreate: boolean;
}

const ModalFooter: FC<ModalFooterProps> = ({ allowCreate }) => {
  const { urlId } = useParams() as { urlId: string };
  const [createURLSummary, { isLoading, error }] = useCreateURLSummaryMutation();
  const createError = error as APIError;

  const handleCreateLCR = useCallback(async () => {
    try {
      await createURLSummary(urlId);
      toast(<ToastCard message='Summary generation initiated' />, { type: 'success', autoClose: 2000 });
    } catch {
      toast(<ToastCard message={`Something went wrong, ${createError?.status} - ${createError?.data.message}`} />, { type: 'error', autoClose: 2000 });
    }
  }, [createURLSummary, urlId, createError]);

  return (
    <div className='p-6'>
      <Button
        className='flex items-center bg-transparent px-0 text-sky-600 hover:bg-transparent hover:text-sky-700 active:bg-transparent disabled:bg-transparent'
        onClick={handleCreateLCR}
        isLoading={isLoading}
        disabled={!allowCreate}
      >
        <PlusCircleIcon className='mr-1 size-6' /> Create Summary
      </Button>
    </div>
  );
};

const TargetAudienceModal: FC<TargetAudienceModalProps> = ({ isOpen, onClose, audiences }) => {
  const { urlId } = useParams() as { urlId: string };
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data: summary } = useGetURLSummaryQuery(urlId, { pollingInterval });

  const initialValues = useMemo(() => {
    const accum: Record<string, boolean> = {};

    audiences.forEach((audience) =>
      audience.purchase_stakeholders.forEach((purchaseStakeholder) => {
        accum[`purchase-stakeholder-modal-${purchaseStakeholder.id}`] = false;
      }),
    );

    return accum;
  }, [audiences]);

  const { getValueByKey, toggleByKey } = useCollapsable(initialValues);

  useEffect(() => {
    if (summary && ['in_progress', 'created'].includes(summary.status)) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [summary]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      title='All Target Audience Questions'
      dialogPanelclassName='w-modal-xl p-0 gap-y-0'
      containerClass='p-6'
      className='max-h-[70vh] overflow-y-auto bg-base-leap'
      footer={<ModalFooter allowCreate={!summary} />}
    >
      {summary && <SummaryCard summary={summary} />}
      <div className='p-6'>
        {audiences?.map((audience) =>
          audience.purchase_stakeholders.map((purchaseStakeholder) => (
            <CollapsableCard
              key={`purchase-stakeholder-modal-${purchaseStakeholder.id}`}
              content={<h4>{purchaseStakeholder.name}</h4>}
              containerClass='bg-white first-of-type:mt-0'
              isOpen={getValueByKey(`purchase-stakeholder-modal-${purchaseStakeholder.id}`)}
              onToggle={() => toggleByKey(`purchase-stakeholder-modal-${purchaseStakeholder.id}`)}
            >
              <ul className='mt-4 list-outside list-disc marker:text-sky-600'>
                {purchaseStakeholder.stakeholder_questions.map((stakeholderQuestion) => (
                  <li key={`stakeholder-question-modal-${stakeholderQuestion.id}`}>{stakeholderQuestion.question}</li>
                ))}
              </ul>
            </CollapsableCard>
          )),
        )}
      </div>
    </Modal>
  );
};

export default TargetAudienceModal;
