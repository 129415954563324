import { SelectField, TextInputField } from '@/components';
import { KEYWORDS_COUNT, URL_SELECTION_OPTIONS, URLS_COUNT } from '@/constants/defaultValues';
import { useField, useFormik } from 'formik';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const AddUrlForm = () => {
  const [field] = useField('url_selection');
  const ctx = useOutletContext() as ReturnType<typeof useFormik<AddUrlFormValues>>;

  useEffect(() => {
    ctx.resetForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex flex-nowrap items-center gap-2'>
      <div className='flex w-96 flex-col gap-4'>
        <SelectField label='Competitors / Keywords' name='competitors_keywords' options={URLS_COUNT} />
        <SelectField label='Keywords' name='keywords' options={KEYWORDS_COUNT} />
        <SelectField label='URL Selection' name='url_selection' options={URL_SELECTION_OPTIONS} />
        {field.value === 'from_domain' && <TextInputField label='Domain / URL' name='domain' />}
      </div>
    </div>
  );
};

export default AddUrlForm;
