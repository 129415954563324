import { FC } from 'react';
import CollapsableCard from '../CollapsableCards/CollapsableCard';
import { useCollapsable } from '@/hooks/collapsable';

interface URLDetailCardProps {
  urlDetail: LCRURL;
}

const URLDetailCards: FC<URLDetailCardProps> = ({ urlDetail }) => {
  const { getValueByKey, toggleByKey } = useCollapsable({
    page_offering: true,
    buyer_wants_to_achieve: true,
  });

  return (
    <div className='flex items-baseline gap-x-6 text-start'>
      <CollapsableCard content={<h4>Page Offering</h4>} containerClass='bg-white w-full py-6' isOpen={getValueByKey('page_offering')} onToggle={() => toggleByKey('page_offering')}>
        <p className='mt-2'>{urlDetail.offering}</p>
      </CollapsableCard>
      <CollapsableCard
        content={<h4>Buyer Wants to Achieve</h4>}
        containerClass='bg-white w-full py-6'
        isOpen={getValueByKey('buyer_wants_to_achieve')}
        onToggle={() => toggleByKey('buyer_wants_to_achieve')}
      >
        <p className='mt-2'>{urlDetail.buyer_wants_to_achieve}</p>
      </CollapsableCard>
    </div>
  );
};

export default URLDetailCards;
