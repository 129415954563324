import { Button, Loading, SearchBarDebounce, SelectFilter, VirtualizedTable } from '@/components';
import { useTable, useTableHandlersWithFilters } from '@/hooks/table';
import { useDeleteReportMutation, useGetReportsQuery } from '@/store/reports/reports.api';
import useReportsTableColumns from './ReportsTableColumns';
import DeleteReportModal from '../DeleteReportModal';
import { useToggle } from '@/hooks/toggle';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { Pagination } from '@/components/Table/components';
import { useFilterContext } from '@/hooks/filters';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useSelector } from 'react-redux';

const SORTING_OPTIONS: Array<OptionType<SortingTypes>> = [
  {
    label: 'Most Recent',
    value: 'desc',
  },
  {
    label: 'Oldest',
    value: 'asc',
  },
];
const INITIAL_PAGE_SIZE = 10;
const ReportsTable = () => {
  const authUser = useSelector(selectAuthUser);
  const [sorting, setSorting] = useState<SortingTypes>('asc');
  const [search, setSearch] = useState<string>('');
  const { changePage, filters } = useFilterContext();
  const { handlePaginationChange, pagination } = useTableHandlersWithFilters(filters);
  const pageSize = useMemo(() => filters?.page_size || INITIAL_PAGE_SIZE, [filters?.page_size]);

  const { data: reports, isLoading: reportsLoading, isFetching: reportsFetching } = useGetReportsQuery({ search, page: filters?.page || 1, page_size: pageSize });
  const [deleteReport, { isLoading: reportsDeleting }] = useDeleteReportMutation();

  const pageCount = useMemo(() => Math.ceil((reports?.count || 0) / pageSize), [reports?.count, pageSize]);
  const { value: isDeleteModalOpen, toggle: toggleDeleteModal } = useToggle();
  const [currentReport, setCurrentReport] = useState<LIRReport | null>(null);
  const [rowsMap, setRowsMap] = useState<Record<number, boolean>>({});

  const columns = useReportsTableColumns();

  const handleSearch = useCallback((v: string) => {
    setSearch(v);
  }, []);

  const handleSortChange = (value: SortingTypes) => {
    table.options.onSortingChange &&
      table.options.onSortingChange([
        {
          id: 'created_at',
          desc: value === 'desc',
        },
      ]);
    setSorting(value);
  };

  const handleOnConfirm = useCallback(async () => {
    if (!currentReport) return;

    await deleteReport(currentReport.id);
    toggleDeleteModal(false);
  }, [currentReport, deleteReport, toggleDeleteModal]);

  const [table, rows] = useTable({
    columns,
    data: reports?.results || [],
    tableCustomOptions: ['allowSort'],
    tableOptions: {
      initialState: {
        columnVisibility: {
          deleteAction: Boolean(authUser?.isSuperuser),
          created_by: !authUser?.isSuperuser,
          companies_id: Boolean(authUser?.isSuperuser),
          downloadAction: !authUser?.isSuperuser,
        },
      },
      meta: {
        rowsMap,
        setRowsMap,
        setCurrentReport,
        toggleDeleteModal,
      },
      onPaginationChange: handlePaginationChange,
      state: {
        pagination: {
          ...pagination,
          pageSize,
        },
      },
      manualPagination: true,
      pageCount,
    },
  });

  useEffect(() => {
    if (!rows.length) return;
    const newRows = rows.reduce((acc, row) => {
      return { ...acc, [row.original.id]: false };
    }, {});

    setRowsMap(newRows);
  }, [rows]);

  return (
    <>
      <div className='rounded-leap bg-white p-4'>
        <div className='flex justify-between pb-2'>
          <SearchBarDebounce isFetching={reportsFetching || reportsLoading} onChange={handleSearch} inputClassName='h-10 placeholder:pl-2 w-96' />
          <SelectFilter label='Sort Reports by' options={SORTING_OPTIONS} value={sorting} onChange={handleSortChange} className='flex-nowrap' withMarker />
        </div>
        <VirtualizedTable isLoading={reportsLoading} loadingText={'Loading LIR Reports...'} containerClass='max-h-[calc(100vh-20rem)] w-full ' table={table} name='reportsTable' />
        <Pagination table={table} onPageChange={changePage} totalRows={rows.length} />
      </div>
      <DeleteReportModal
        isOpen={isDeleteModalOpen}
        footer={
          <div className={'mt-4 flex w-full items-center gap-6'}>
            <Button variant='outline-light' onClick={() => toggleDeleteModal(false)} disabled={reportsDeleting} className='w-full'>
              Cancel
            </Button>
            <Button onClick={handleOnConfirm} disabled={reportsDeleting} className='w-full whitespace-nowrap'>
              {reportsDeleting ? <Loading className='mx-auto h-5 w-5' /> : 'Confirm'}
            </Button>
          </div>
        }
        reportName={currentReport?.name || ''}
      />
    </>
  );
};

export default ReportsTable;
