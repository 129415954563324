import { Button, Checkbox, Modal, ModalProps, SearchBarDebounce, Select, VirtualizedTable, WarningModal } from '@/components';
import { useScreenSize } from '@/hooks/screenSize';
import { useTable } from '@/hooks/table';
import { useToggle } from '@/hooks/toggle';
import useTrackTableChanges from '@/hooks/trackTableChanges';
import { useGetTopKeywordsQuery } from '@/store/campaign/campaign.api';

import { createColumnHelper } from '@tanstack/react-table';
import { useField } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

type PullTopKeywordsModalProps = ModalProps & {
  onConfirm: () => void;
  field: string;
};

const ModalFooter = ({ onConfirm, disabled }: { onConfirm: () => void; disabled: boolean }) => {
  return (
    <Button onClick={onConfirm} disabled={disabled} className='ml-auto'>
      Confirm
    </Button>
  );
};

type PullTopKeywordsColumn = {
  keyword: string;
  volume: number;
};

const formatTopKeywords = (topKeywords: TopKeywords[]) => {
  return topKeywords.map((keyword) => ({
    keyword: keyword.keyword,
    volume: keyword.searchVolume,
  }));
};

const columnHelper = createColumnHelper<PullTopKeywordsColumn>();

const keywordSearchTypesOptions: Array<OptionType<KeywordSearchType>> = [
  { value: 'exact-url', label: 'Exact URL' },
  { value: 'domain', label: 'Root Domain' },
  { value: 'subdomain', label: 'Subdomain' },
  { value: 'subfolder', label: 'Subfolder' },
];

const PullTopKeywordsModal: FC<PullTopKeywordsModalProps> = ({ onClose, onConfirm, field }) => {
  const [{ value: keywordCount }] = useField<number>('keyword_count');
  const [{ value: fieldKeywords }, , keywordsHelper] = useField<string[]>(`${field}.keywords`);
  const [countryField] = useField<string>('country');
  const country = useMemo(() => countryField.value || 'us', [countryField]);
  const [urlField] = useField<string>(`${field}.url`);
  const [tableSearchFilter, setTableSearchFilter] = useState('');
  const [searchType, setSearchType] = useState<KeywordSearchType>('exact-url');
  const {
    data: topKeywords,
    isLoading,
    isFetching,
  } = useGetTopKeywordsQuery({ url: urlField.value, country, limit: keywordCount, keyword_containing: tableSearchFilter, search_type: searchType });
  const { value: warningModalOpen, toggle: toggleWarningModal } = useToggle();

  const { trackTableChanges: trackChanges, hasChanges } = useTrackTableChanges();

  const formattedKeywords = useMemo(() => {
    if (topKeywords) {
      return formatTopKeywords(topKeywords.results);
    }
    return [];
  }, [topKeywords]);

  const { width } = useScreenSize();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />,
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onChange={(e) => {
              trackChanges(row.id);
              row.getToggleSelectedHandler()(e);
            }}
          />
        ),
        size: 45,
      }),
      columnHelper.accessor('keyword', {
        header: () => <p className='text-left'>Keyword</p>,
        cell: ({ getValue }) => <p className='line-clamp-1 text-ellipsis break-all'>{getValue()}</p>,
      }),
      columnHelper.accessor('volume', {
        header: () => <p className='text-left'>Volume</p>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 120,
      }),
    ],
    [trackChanges],
  );

  const [table, rows] = useTable({
    data: formattedKeywords,
    columns,
    tableCustomOptions: ['allowSelect', 'allowFilters', 'allowResize', 'allowSort'],
    tableOptions: {
      meta: {
        size: (width * 0.557) / columns.length,
      },
    },
  });

  useEffect(() => {
    rows.forEach((row) => {
      if (fieldKeywords && fieldKeywords.includes(row.original.keyword)) {
        row.toggleSelected(true);
      }
    });
  }, [fieldKeywords, rows]);

  const handleSearch = useCallback(
    (val: string) => {
      if (table.getIsSomeRowsSelected()) {
        table.toggleAllRowsSelected(false);
      }
      setTableSearchFilter(val);
    },
    [table],
  );

  const handleConfirm = () => {
    const keywords = rows.map((row) => row.original.keyword);
    keywordsHelper.setValue([...fieldKeywords, ...keywords]);
    onConfirm();
  };

  const handleOnEscapeKeyUp = () => {
    if (hasChanges) {
      return toggleWarningModal(true);
    }

    onClose && onClose();
  };

  const handleWarningModalClose = () => toggleWarningModal(false);
  const handleWarningModalConfirm = () => {
    toggleWarningModal(false);
    onClose && onClose();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      onEscapeKeyUp={handleOnEscapeKeyUp}
      title='Pull Top Keyword'
      footer={<ModalFooter onConfirm={handleConfirm} disabled={rows.length === 0} />}
      dialogPanelclassName='2xl:w-[60%] 2xl:max-w-[60%]'
    >
      <WarningModal isOpen={warningModalOpen} onClose={handleWarningModalClose} onConfirm={handleWarningModalConfirm} />
      <div className='mb-2 flex w-full items-center justify-between'>
        <SearchBarDebounce delay={750} className='w-5/6' isFetching={isLoading || isFetching} inputClassName='h-10 w-full rounded-md bg-slate-100' onChange={handleSearch} />
        <Select className='w-full' value={searchType} onChange={(selection) => setSearchType(selection)} options={keywordSearchTypesOptions} />
      </div>

      <VirtualizedTable
        containerClass='max-h-96 overflow-scroll'
        table={table}
        name='pullTopKeywordsModal'
        allowResize
        isLoading={isLoading || isFetching}
        loadingText='Loading Top Keywords...'
      />
    </Modal>
  );
};

export default PullTopKeywordsModal;
