import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { Column } from '@tanstack/react-table';

type queryStringValues<T> = [T, (value: Partial<T>) => void, (value: keyof Partial<T>) => void, (value: T) => void];

const setQsParams = (searchParams: URLSearchParams, navigate: NavigateFunction) => {
  navigate(`?${searchParams.toString()}`);
};

const getBackgroundColor = (isPinned: string | boolean, isHeader: boolean, isLastLeftPinnedColumn: boolean, changeLastPinnedColumnColor: boolean): string | undefined => {
  if (!isPinned || (isLastLeftPinnedColumn && !changeLastPinnedColumnColor)) {
    return undefined;
  }

  if (isHeader) {
    return 'rgb(241 245 249)';
  }

  return 'white';
};

const getZIndex = (isPinned: string | boolean, isLastLeftPinnedColumn: boolean) => (isPinned ? (isLastLeftPinnedColumn ? 1 : 2) : undefined);

const getColumnPinningStyles = <T extends unknown>(column: Column<T, unknown>, isHeader = false, changeLastPinnedColumnColor = false): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: getZIndex(isPinned, isLastLeftPinnedColumn),
    backgroundColor: getBackgroundColor(isPinned, isHeader, isLastLeftPinnedColumn, changeLastPinnedColumnColor),
  };
};

const useQueryStringValues = <T extends object>(initialValue: T): queryStringValues<T> => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const queryStringPrefilledValues = useMemo(
    () =>
      (Object.keys(initialValue) as Array<keyof T>).reduce((prefilled, key) => {
        const qsValue = searchParams.get(key.toString()) || initialValue[key];

        if (!qsValue) {
          return prefilled;
        }

        return {
          ...prefilled,
          [key]: typeof initialValue[key] === 'number' ? Number(qsValue) : qsValue,
        };
      }, {} as T),
    [initialValue, searchParams],
  );

  const [value, setValue] = useState<T>(queryStringPrefilledValues);

  const onAppendValue = useCallback(
    (newValue: Partial<T>) => {
      setValue((currentValue) => ({ ...currentValue, ...newValue }));
      Object.keys(newValue).forEach((param) => {
        if (newValue[param as keyof Partial<T>] === undefined) {
          return searchParams.delete(param);
        }
        searchParams.set(param, String(newValue[param as keyof Partial<T>]));
      });

      setQsParams(searchParams, navigate);
    },
    [searchParams, navigate],
  );

  const onDeleteValue = useCallback(
    (newValue: keyof Partial<T>) => {
      setValue((currentValue) => {
        delete currentValue[newValue];
        return currentValue;
      });
      searchParams.delete(newValue.toString());

      setQsParams(searchParams, navigate);
    },
    [searchParams, navigate],
  );

  const onSetValue = useCallback(
    (newValue: T) => {
      setValue(newValue);

      const newUrlParams = new URLSearchParams();

      Object.keys(newValue).forEach((param) => {
        newUrlParams.set(param, String(newValue[param as keyof Partial<T>]));
      });

      setQsParams(newUrlParams, navigate);
    },
    [navigate],
  );

  return [value, onAppendValue, onDeleteValue, onSetValue];
};

export { useQueryStringValues, getColumnPinningStyles };
