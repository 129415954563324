import { FC, PropsWithChildren } from 'react';
import { Collapsable } from '@/components';
import cn from '@/utils/style';

interface CollapsableCardProps extends PropsWithChildren {
  content: JSX.Element | null;
  containerClass?: string;
  isOpen: boolean;
  onToggle: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const CollapsableCard: FC<CollapsableCardProps> = ({ children, content, containerClass, isOpen, onToggle, disabled, isLoading }) => (
  <div className={cn('relative mt-6 rounded-leap p-4 shadow-sm', containerClass)}>
    <Collapsable isOpen={isOpen} onToggle={onToggle} content={content} disabled={disabled}>
      <div>{children}</div>
    </Collapsable>
    {isLoading && <div className='absolute bottom-0 left-0 right-0 top-0 animate-pulse rounded-leap bg-gray-400 bg-opacity-30'></div>}
  </div>
);

export default CollapsableCard;
