import { getColumnPinningStyles } from '@/hooks/utils';
import cn from '@/utils/style';
import { Cell, Column, flexRender, Row as RowType } from '@tanstack/react-table';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { useCallback } from 'react';

interface RowProps<T> {
  row: RowType<T>;
  virtualRow?: VirtualItem<Element>;
  virtualizer?: Virtualizer<HTMLDivElement, Element>;
  className?: string;
  cellStyle?: string;
  isVirtualized?: boolean;
  allowResize?: boolean;
  renderSubComponent?: (row: RowType<T>) => JSX.Element;
}

const Row = <T extends unknown>({ row, virtualRow, virtualizer, cellStyle, className, isVirtualized = false, allowResize = false }: RowProps<T>) => {
  const getPinningStyles = useCallback((column: Column<T, unknown>) => getColumnPinningStyles(column, false, true), []);

  const measureElement = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && virtualizer) {
        virtualizer.measureElement(node);
      }
    },
    [virtualizer],
  );

  const renderCells = useCallback(
    (cell: Cell<T, unknown>) => (
      <td
        key={cell.id}
        className={cn('relative px-4 py-2', cellStyle)}
        style={{ width: allowResize ? `calc(var(--col-${cell.column.id}-size) * 1px)` : cell.column.getSize(), ...getPinningStyles(cell.column) }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    ),
    [cellStyle, allowResize, getPinningStyles],
  );

  if (virtualRow && virtualizer) {
    return (
      <tr
        data-index={virtualRow.index} // needed for dynamic row height measurement
        ref={measureElement} // measure dynamic row height
        className={cn(
          'bg-white last-child-first:rounded-bl-md last-child-last:rounded-br-md',
          isVirtualized && row.index % 2 === 0 && 'bg-gray-50',
          allowResize && 'flex',
          className,
        )}
      >
        {row.getVisibleCells().map(renderCells)}
      </tr>
    );
  }

  return (
    <tr className={cn('bg-white last-child-first:rounded-bl-md last-child-last:rounded-br-md', isVirtualized && row.index % 2 === 0 && 'bg-gray-50', className)}>
      {row.getVisibleCells().map(renderCells)}
    </tr>
  );
};

export default Row;
