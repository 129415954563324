import { RootState } from '../store';
import { areScenariosValid } from '@/utils/scenarios';

const selectCountryList = (state: RootState) => state.campaign.countryList;
const selectEditAllScenarios = (state: RootState) => state.campaign.editAllScenarios;
const selectScenarios = (state: RootState) => Object.values(state.campaign.scenarios);
const selectSelectedScenario = (state: RootState) => {
  if (!state.campaign.selectedScenario) {
    return;
  }

  return state.campaign.scenarios[state.campaign.selectedScenario];
};

const selectAllScenariosAreValid = (state: RootState) => areScenariosValid(Object.values(state.campaign.scenarios));

const selectCampaign = (state: RootState) => state.campaign.campaign;
const selectCampaignStatus = (state: RootState) => state.campaign.campaign?.status;
const selectCampaignStatusIs = (state: RootState, status: CampaignStatus) => state.campaign.campaign?.status === status;
const selectCampaignAnalysis = (state: RootState) => state.campaign.campaignAnalysis;
const selectMaxGapRatio = (state: RootState) => state.campaign.maxGapRatio;
const selectSteps = (state: RootState) => state.campaign.steps;
const selectKeywords = (state: RootState) => state.campaign.keywords;
const selectLinksToBuildState = (state: RootState) => state.campaign.linksToBuildState;
const selectCloseToGapState = (state: RootState) => state.campaign.closeToGapState;

export {
  selectCountryList,
  selectEditAllScenarios,
  selectAllScenariosAreValid,
  selectScenarios,
  selectSelectedScenario,
  selectCampaign,
  selectCampaignStatus,
  selectCampaignStatusIs,
  selectCampaignAnalysis,
  selectMaxGapRatio,
  selectSteps,
  selectKeywords,
  selectLinksToBuildState,
  selectCloseToGapState,
};
