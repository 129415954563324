import { Button, ErrorMessage, ReadOnlyFooter } from '@/components';
import { useSteps } from '@/hooks/steps';
import { selectCampaignStatusIs } from '@/store/campaign/campaign.selector';
import { RootState } from '@/store/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type AnalysisFooterProps = {
  onSave?: () => void | Promise<void>;
  onNext: () => void | Promise<void>;
  onPrevious?: () => void;
  error?: APIError;
  nextText?: string;
  saveDisabled?: boolean;
  nextDisabled?: boolean;
};

const AnalysisFooter: FC<AnalysisFooterProps> = ({ onSave, saveDisabled, nextDisabled, error, onNext, onPrevious, nextText = 'Next' }) => {
  const campaignStatusIsKickedOff = useSelector((state: RootState) => selectCampaignStatusIs(state, 'kicked_off'));
  const navigate = useNavigate();
  const { previousStep, nextStep, isAt } = useSteps();

  if (campaignStatusIsKickedOff && isAt('summary')) {
    return <ReadOnlyFooter onNext={onNext} onPrevious={() => navigate(`../${previousStep}`)} nextButtonText={nextText} />;
  }

  if (campaignStatusIsKickedOff) {
    return <ReadOnlyFooter onNext={() => navigate(`../${nextStep}`)} onPrevious={() => navigate(`../${previousStep}`)} nextButtonText={nextText} />;
  }

  const handlePreviousClick = async () => {
    onSave && (await onSave());
    onPrevious && onPrevious();
  };

  return (
    <div className='flex items-center justify-end gap-2 py-4'>
      {onSave && (
        <Button onClick={onSave} disabled={saveDisabled} className='mr-auto' variant='outline-light'>
          Save as Draft
        </Button>
      )}
      {error && <ErrorMessage>{error.data.message}</ErrorMessage>}
      <Button variant='outline-light' disabled={!onPrevious} onClick={handlePreviousClick}>
        Previous
      </Button>
      <Button onClick={onNext} disabled={nextDisabled}>
        {nextText}
      </Button>
    </div>
  );
};

export default AnalysisFooter;
