const AVAILABLE_TO_RETRY = [
  'html',
  'url_and_description',
  'target_audience',
  'stakeholders',
  'stakeholder_questions',
  'worse_case_scenario',
  'expert_questions',
  'questions_summary',
  'openai_server_error',
  'openai_service_overloaded',
];

const LCR_MESSAGES = {
  error: {
    html: 'Something went wrong. Please try again.',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
    inexistent_page: 'Inexistent page.',
    page_not_found: 'Page not found.',
    openai_authentication_error: 'Access Error. Please contact our support team.',
    openai_permission_error: 'Access Error. Please contact our support team.',
    openai_not_found: 'Access Error. Please contact our support team.',
    openai_rate_limit_error: 'We’re experiencing high demand. Please wait a few moments and try again or contact support if the issue persists.',
    openai_server_error: 'We’re experiencing technical difficulties. Please try again later or contact support.',
    openai_service_overloaded: 'The service is temporarily unavailable. Please try again shortly.',
  },
  in_progress: {
    html: 'Fetching HTML',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
  },
};

export const isLCRInProgress = (status: Status) => ['in_progress', 'created', 'queued'].includes(status);

export const isLCRAvailableToRetry = (status: Status, message: Message | null) => status === 'error' && AVAILABLE_TO_RETRY.includes(message || '');

export const getLCRMessage = (status: Status, message: Message | null) => (message && (status === 'error' || status === 'in_progress') ? LCR_MESSAGES[status][message] : null);
