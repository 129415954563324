import { Modal, ModalProps, SearchBarDebounce, VirtualizedTable, WarningModal } from '@/components';
import { useGetDomainOrganicPagesQuery, useGetTopKeywordsQuery } from '@/store/campaign/campaign.api';
import { useField } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';
import OrganicResearchTableFooter from './OrganicResearchTableFooter';
import useOrganicResearchColumns from './useOrganicResearchColumns';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Row } from '@tanstack/react-table';
import DomainInputFieldSkeleton from '@/pages/NewCampaign/components/DomainInputField/DomainInputFieldSkeleton';
import { useOrganicResearchTable, useSelectUrls } from '@/hooks/organicResearch';
import { useToggle } from '@/hooks/toggle';

type OrganicResearchModalProps = ModalProps & {
  name: string;
  url: string;
  country: string;
};

const PAGE_SIZE = 30;

const OrganicResearchModal: FC<OrganicResearchModalProps> = ({ name, url, country, onClose, onConfirm, isLoading }) => {
  const [, , { setValue }] = useField<string>(name);
  const [params, setParams] = useState({ search: '', offset: 0, limit: PAGE_SIZE });
  const { data: organicPages, isLoading: isOrganicPagesLoading, isFetching: isOrganicPagesFetching } = useGetDomainOrganicPagesQuery({ url, country, ...params });
  const data = useMemo(() => organicPages?.results || [], [organicPages]);

  const loadingState = isLoading || isOrganicPagesLoading;
  const [selectedUrls, toggleSelectedUrl, toggleAll] = useSelectUrls();
  const { value: warningModalOpen, toggle: toggleWarningModal } = useToggle();

  const organicResearchColumns = useOrganicResearchColumns({ selectedUrls, toggleSelectedUrl, toggleAll });

  const [table, , width] = useOrganicResearchTable(data, organicResearchColumns);

  const handleConfirm = useCallback(() => {
    setValue(selectedUrls.join(', '));
    onConfirm && onConfirm();
    onClose && onClose();
  }, [setValue, onClose, onConfirm, selectedUrls]);

  const handleSearch = useCallback(
    (value: string) => {
      if (value === params.search) return;

      table.toggleAllRowsExpanded(false);
      setParams((old) => ({ ...old, search: value, offset: 0, limit: PAGE_SIZE }));
    },
    [table, params],
  );

  const handleFetchAdditional = useCallback(() => {
    setParams((old) => ({ ...old, offset: old.offset + PAGE_SIZE, limit: old.limit + PAGE_SIZE }));
  }, []);

  const renderSelectedRows = (url: string) => (
    <div key={url} className='flex w-fit flex-nowrap items-center gap-2 whitespace-nowrap rounded-lg bg-blue-100 px-3 py-2'>
      <p>{url}</p>
      <button onClick={() => toggleSelectedUrl(url)}>
        <XMarkIcon className='w-5' />
      </button>
    </div>
  );

  const renderSub = (row: Row<OrganicResearch>) => <TopKeywordSubComponent row={row} country={country} />;

  const handleOnEscapeKeyUp = () => {
    if (selectedUrls.length > 0) {
      return toggleWarningModal(true);
    }
    return onClose && onClose();
  };

  const handleWarningModalConfirm = () => {
    toggleWarningModal(false);
    onClose && onClose();
  };

  const handleWarningModalClose = () => {
    toggleWarningModal(false);
  };
  return (
    <Modal
      isOpen
      onClose={onClose}
      onEscapeKeyUp={handleOnEscapeKeyUp}
      title='Select URLs for Campaign'
      dialogPanelclassName='2xl:w-[80%] 2xl:max-w-[80%] max-h-[80%]'
      footer={<OrganicResearchTableFooter onConfirm={handleConfirm} onFetchAdditional={handleFetchAdditional} isLoading={loadingState} amount={selectedUrls.length} />}
      isLoading={loadingState}
      loadingComponent={<DomainInputFieldSkeleton />}
    >
      <WarningModal
        message='Your Progress will be Lost, Are you sure you want to close?'
        isOpen={warningModalOpen}
        onConfirm={handleWarningModalConfirm}
        onClose={handleWarningModalClose}
      />
      <div className='flex flex-col gap-5'>
        <SearchBarDebounce delay={750} onChange={handleSearch} inputClassName='w-full h-10' className='w-full' isFetching={isOrganicPagesFetching} />
        <VirtualizedTable
          name='organicResearch'
          table={table}
          allowResize
          subRowContent={renderSub}
          itemHeight={100}
          containerClass='max-h-[calc(60vh-12rem)]'
          style={{ maxWidth: width * 0.77 }}
        />
        {selectedUrls.length > 0 && (
          <div className='flex max-h-24 flex-wrap items-center gap-3 overflow-y-auto rounded-lg bg-slate-100 p-4' style={{ maxWidth: width * 0.77 }}>
            {selectedUrls.map(renderSelectedRows)}
          </div>
        )}
      </div>
    </Modal>
  );
};

const TopKeywordSubComponent: FC<{ row: Row<OrganicResearch>; country: string }> = ({ row, country }) => {
  const { data, isLoading, isFetching } = useGetTopKeywordsQuery({ url: row.original.url, country, limit: 10 });

  const content = () => {
    if (isLoading || isFetching) {
      return <span className='w-full text-center'>...loading</span>;
    }

    if (!data) {
      return <span className='w-full text-center'>no data...</span>;
    }

    return data.results.map((kw) => kw.keyword).join('; ');
  };

  return (
    <div className='w-full  rounded-b-xl bg-slate-200'>
      <div className='overflow-auto px-4 py-2'>{content()}</div>
    </div>
  );
};
export default OrganicResearchModal;
export { TopKeywordSubComponent };
