import { useCallback, useState } from 'react';

const useCollapsable = (initialValue: Record<string, boolean> = {}) => {
  const [value, setValue] = useState(initialValue);

  const getValueByKey = useCallback((key: string) => value[key], [value]);
  const toggleByKey = (key: string) => setValue((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  const expandAll = () =>
    setValue((prevState) => {
      const newValues: Record<string, boolean> = {};

      Object.keys(prevState).forEach((key) => (newValues[key] = true));

      return { ...prevState, ...newValues };
    });

  return { value, setValue, getValueByKey, toggleByKey, expandAll };
};

export { useCollapsable };
