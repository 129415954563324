import cn from '@/utils/style';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import Button from '../Button';
import Skeleton from '../Skeleton';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  title?: string | JSX.Element;
  onClose?: () => void;
  onConfirm?: () => void;
  containerClass?: string;
  className?: string;
  dialogPanelclassName?: string;
  footerClassName?: string;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
  errorMessage?: string;
  footer?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  buttonsLoading?: boolean;
  onEscapeKeyUp?: () => void;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  onClose,
  children,
  containerClass,
  className,
  dialogPanelclassName,
  footerClassName,
  onConfirm,
  footer,
  isLoading = false,
  loadingComponent,
  errorMessage = '',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  buttonsLoading = false,
  onEscapeKeyUp,
}) => {
  useEffect(() => {
    const close = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (onEscapeKeyUp) {
          return onEscapeKeyUp();
        }

        onClose && onClose();
      }
    };

    window.addEventListener('keyup', close);
    return () => window.removeEventListener('keyup', close);
  }, [onClose, onEscapeKeyUp]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-40' onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex h-screen justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={cn(
                  'm-auto flex h-fit transform flex-col  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                  onClose && 'gap-y-6',
                  dialogPanelclassName,
                )}
              >
                {isLoading ? (
                  <Fragment>
                    {loadingComponent || (
                      <div className='absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-2xl bg-gray-300 opacity-100'>
                        <Skeleton className='h-full w-full' />
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={cn('flex items-center justify-between', containerClass)}>
                      <Dialog.Title className='text-xl font-bold'>{title}</Dialog.Title>
                      {onClose && !buttonsLoading && <XMarkIcon className='w-7 cursor-pointer text-gray-400' onClick={onClose} />}
                    </div>
                    <div className={className}>{children}</div>
                    {errorMessage && <p className='ml-auto mr-4 text-red-500'>{errorMessage}</p>}
                    {onConfirm && (
                      <div className={cn('flex w-full items-center gap-6', footerClassName)}>
                        <Button variant='outline-light' onClick={onClose} disabled={buttonsLoading} className='w-full'>
                          {cancelButtonText}
                        </Button>
                        <Button onClick={onConfirm} isLoading={buttonsLoading} className='w-full whitespace-nowrap'>
                          {confirmButtonText}
                        </Button>
                      </div>
                    )}
                    {footer && <>{footer}</>}
                  </Fragment>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
export type { ModalProps };
