import { Button, ProgressBar, Skeleton, SuccessModal } from '@/components';
import AnalysisCard from '../AnalysisCard';
import apiPOC, { useCreateURLAudienceMutation, useGetURLDetailQuery, useRetryURLAudienceMutation } from '@/store/poc/poc.api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { getLCRMessage, isLCRAvailableToRetry, isLCRInProgress } from '@/utils/lcr';
import { xxHash32 } from 'js-xxhash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from '@/hooks/toggle';
import { useNavigate } from 'react-router-dom';
import LCRIcon from '@/components/Icons/LCRIcon';
import { selectAuthUser } from '@/store/auth/auth.selector';

const LinkingContextReportErrorCard = ({ urlDetail }: { urlDetail: LCRURL }) => {
  const [retryURLAudience, { isLoading }] = useRetryURLAudienceMutation();
  const dispatch = useDispatch<AppDispatch>();
  const { id, status, message } = urlDetail;

  const handleRetryLCR = async () => {
    await retryURLAudience({ id });
    dispatch(apiPOC.util.invalidateTags(['getURLDetail']));
  };

  return (
    <div className='flex flex-col items-center justify-center gap-y-2 text-black'>
      <ExclamationTriangleIcon className='size-20 text-red-500' />
      <p className='text-red-500'>Error!</p>
      <p>{getLCRMessage(status, message)}</p>
      <Button variant='warning' className='text-base' onClick={() => handleRetryLCR()} isLoading={isLoading} disabled={!isLCRAvailableToRetry(status, message)}>
        Try Again
      </Button>
    </div>
  );
};

const TargetAudienceCard = ({ urlDetail }: { urlDetail: LCRURL }) => {
  if (isLCRInProgress(urlDetail.status)) {
    return (
      <div className='flex flex-col items-center justify-center gap-y-2 text-black'>
        <p>{urlDetail.status === 'queued' ? 'Queued' : 'Creating...'}</p>
        {urlDetail.status !== 'queued' && <ProgressBar progress={urlDetail.progress || 0} showProgress={false} color='bg-green-700' className='my-1 w-48' />}
      </div>
    );
  }

  if (urlDetail.status === 'error') {
    return <LinkingContextReportErrorCard urlDetail={urlDetail} />;
  }

  return (
    <div className='flex'>
      <div className='w-full'>
        <div className='grid w-full grid-cols-[200px_1fr] place-content-end gap-2 rounded-sm bg-base-leap p-2  text-base font-semibold text-black'>
          <span className='text-left'>Target Audience</span>
          <span className='text-left'>Description</span>
        </div>
        <div className='flex max-h-[150px] w-full flex-col overflow-y-auto text-base text-black'>
          {urlDetail.target_audience?.map(({ id, name, description }) => (
            <div className='grid grid-cols-[200px_1fr] place-content-start gap-2 border-b-[1px] border-solid border-b-slate-200 p-2 last:border-b-0' key={id}>
              <span className='truncate text-left'>{name}</span>
              <span className=' truncate text-left'>{description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LinkingContextReportCard = ({ url }: { url: string }) => {
  const authUser = useSelector(selectAuthUser);

  const urlHash = xxHash32(url).toString(16);
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data: urlDetail, isLoading: urlDetailIsLoading } = useGetURLDetailQuery({ urlIdOrHash: urlHash, include: ['target_audience'] }, { pollingInterval });
  const [createLCR, { isLoading }] = useCreateURLAudienceMutation();
  const { value: isSuccessCreateLCRModalOpen, toggle: toggleSuccessCreateLCRModal } = useToggle();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (urlDetail && isLCRInProgress(urlDetail.status)) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [urlDetail]);

  const handleCreateLCR = useCallback(async () => {
    try {
      await createLCR({ url });
      dispatch(apiPOC.util.invalidateTags(['getURLDetail']));
      toggleSuccessCreateLCRModal(true);
    } catch {
      //
    }
  }, [createLCR, toggleSuccessCreateLCRModal, url, dispatch]);

  const extraButton = useMemo(() => {
    if (urlDetail && urlDetail.status === 'completed') {
      return { title: 'View More', onClick: () => navigate(`/linking-context-report/url/${urlDetail.id}/detail/`) };
    }

    return undefined;
  }, [urlDetail, navigate]);

  if (!authUser?.config.show_linking_context_report && !authUser?.isSuperuser) {
    return null;
  }

  if (urlDetailIsLoading) {
    return <Skeleton className='h-[10rem] w-full' />;
  }

  return (
    <AnalysisCard title='Linking Context Report' className='h-full w-full text-nowrap p-6' extraButton={extraButton}>
      {urlDetail ? (
        <TargetAudienceCard urlDetail={urlDetail} />
      ) : (
        <div className='flex flex-col items-center justify-center gap-y-2 text-black'>
          <LCRIcon className='size-20 text-slate-400' />
          <p>You don’t have linking context report yet!</p>
          <p className='text-slate-500'>Create an LCR to explore more information about the link</p>
          <Button className='mx-auto block text-base' onClick={() => handleCreateLCR()} isLoading={isLoading}>
            Create LCR
          </Button>
        </div>
      )}
      <SuccessModal isOpen={isSuccessCreateLCRModalOpen} message='Your LCR has been successfully created' onConfirm={() => toggleSuccessCreateLCRModal(false)} />
    </AnalysisCard>
  );
};

export default LinkingContextReportCard;
